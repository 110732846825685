import React, { useEffect, useState } from 'react'
import { AddCircle, Close } from '@mui/icons-material'
import { Dialog, DialogContent, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Table from '../../../shared/ui/Table'
import { useDeleteRequest, useGetRequest, usePostRequest, usePutRequest } from '../../../shared/hooks/requests'
import { CAMERA_LINES, CAMERA_ROI, LINE_LIST, ROI_LIST } from '../../../shared/utils/urls'
import RoiTableItem from './RoiTableItem'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'
import Canvas from './Canvas'
import { PolygonInitialState,
    setActivePolygonIndex,
    setPolygons,
    updatePolygonLabel } from '../../../shared/store/polygonSlice'
import { hexToRGBA } from '../../../shared/utils/string'
import { COLORS } from '../../../shared/utils/colors'
import LineCrossing from './LineCrossing'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

const cols = [
    { id: 1, title: 'Name' },
    { id: 2, title: 'Created time' },
    { id: 3, title: 'Action', align: 'right' },
]

const InitializeLineData = (data) => {
    const line = {
        id: data.id,
        edit: true,
        isFinished: true,
        label: data.name,
        description: data.description,
        type: data.type,
        cameraId: data.cameraId,
        points: [{ x: data.x1, y: data.y1, orderNumber: 1 }, { x: data.x2, y: data.y2, orderNumber: 2 }],
        flattenedPoints: [data.x1, data.y1, data.x2, data.y2],
        colors: {
            vertexRadius: 2.5,
            lineColor: data.color || '#0B9E34',
            fillColor: hexToRGBA(data.color || '#0B9E34', '0.30'),
            vertexColor: COLORS.white,
            vertexStrokeWidth: 1,
        },
    }

    const direction = {
        id: data.id,
        edit: true,
        isFinished: true,
        label: data.name,
        cameraId: data.cameraId,
        description: data.description,
        type: data.type,
        points: [{ x: data.dx1, y: data.dy1, orderNumber: 1 }, { x: data.dx2, y: data.dy2, orderNumber: 2 }],
        flattenedPoints: [data.dx1, data.dy1, data.dx2, data.dy2],
        colors: {
            vertexRadius: 2.5,
            lineColor: data.color || '#0B9E34',
            fillColor: hexToRGBA(data.color || '#0B9E34', '0.30'),
            vertexColor: COLORS.white,
            vertexStrokeWidth: 1,
        },
    }

    return { line, direction }
}

export default function RoiTable({ camera, section }) {
    const dispatch = useDispatch()
    const { handleErrorMsg } = useHandleErrors()
    const [open, setOpen] = useState(false)
    const [formVisible, setFormVisible] = useState(false)
    const [currentPolygon, setCurrentPolygon] = useState(null)
    const imageSrc = camera.snapshots.length ? camera.snapshots[camera.snapshots.length - 1].snapshotUrl : ''
    const getRois = useGetRequest({ url: CAMERA_ROI.replace('{id}', camera.id) })
    const getLines = useGetRequest({ url: CAMERA_LINES.replace('{id}', camera.id) })
    const lines = getLines.response ? getLines.response : []
    const rois = getRois.response ? getRois.response : []
    const { polygons } = useSelector((state) => state.polygon)

    const createRoi = usePostRequest({ url: ROI_LIST })
    const updateRoi = usePutRequest()
    const deleteRoi = useDeleteRequest({ url: ROI_LIST })

    const createLine = usePostRequest({ url: LINE_LIST })
    const updateLine = usePutRequest()
    const deleteLine = useDeleteRequest({ url: LINE_LIST })

    useEffect(() => {
        if (section === 'roi') {
            getRois.request()
        } else {
            getLines.request()
        }
    }, [section])

    const parseAndFormatTime = (timeValue) => {
        if (!timeValue) return null
        // Define possible input formats
        const inputFormats = [
            'HH:mm:ss',
            'HH:mm:ss.SSS[Z]',
            'YYYY-MM-DDTHH:mm:ss.SSSSSS',
            moment.ISO_8601,
        ]
        // Attempt to parse the timeValue using the possible formats
        const parsedTime = moment(timeValue, inputFormats, true)
        if (!parsedTime.isValid()) {
            // Handle invalid time format
            console.error('Invalid time format:', timeValue)
            return null
        }
        // Format the time for the backend (adjust the format as needed)
        return parsedTime.format('HH:mm:ss.SSS[Z]')
    }

    const onSubmit = async (values) => {
        if (currentPolygon) {
            const updatedPolygon = { ...currentPolygon, ...values }

            const updatedPolygons = polygons.map((p) => (p.id === updatedPolygon.id ? updatedPolygon : p))
            dispatch(updatePolygonLabel({ id: updatedPolygon.id, info: updatedPolygon }))
            dispatch(setPolygons({ polygons: updatedPolygons, shouldUpdateHistory: true }))

            // const safeZoneStartTime = values.safeZoneStartTime
            //     ? moment(values.safeZoneStartTime).format('HH:mm:ss.SSS[Z]') : null
            // const safeZoneEndTime = values.safeZoneEndTime
            //     ? moment(values.safeZoneEndTime).format('HH:mm:ss.SSS[Z]') : null
            const safeZoneStartTime = parseAndFormatTime(values.safeZoneStartTime)
            const safeZoneEndTime = parseAndFormatTime(values.safeZoneEndTime)

            const { success, response, error } = await (currentPolygon.edit ? updateRoi : createRoi).request({
                url: currentPolygon.edit ? `${ROI_LIST}/${values.id}` : ROI_LIST,
                data: {
                    cameraId: camera.id,
                    name: values.label,
                    description: values.description,
                    labels: values.labels,
                    people_count_threshold: values.peopleCountThreshold,
                    safe_zone_start_time: safeZoneStartTime,
                    safe_zone_end_time: safeZoneEndTime,
                    identity_id: values.identityId,
                    workspace_type: values.workspaceType,
                    color: currentPolygon.colors.lineColor,
                    points: currentPolygon.points.map((p) => ({
                        x: Math.round(p.x),
                        y: Math.round(p.y),
                        orderNumber: p.orderNumber,
                        roiId: p.roiId || undefined,
                        id: p.id || undefined,
                    })),
                },
            })

            if (success) {
                const newRoi = {
                    ...response,
                    edit: true,
                    isFinished: response.points.length > 0,
                    flattenedPoints: response.points.flatMap((point) => [point.x, point.y]),
                    colors: {
                        vertexRadius: 2.5,
                        lineColor: response.color || '#0B9E34',
                        fillColor: hexToRGBA(response.color || '#0B9E34', '0.30'),
                        vertexColor: COLORS.white,
                        vertexStrokeWidth: 1,
                    },
                    // labels, check if it is an array of objects or strings, if objects, map to labelTitle
                    labels: response.labels.map((label) => label.labelTitle),
                    label: response.name,
                    description: response.description,
                    userId: response.user_id,
                    type: response.type,
                }
                if (currentPolygon.edit) {
                    getRois.setResponse((prev) => prev
                        .map((item) => (item.id === currentPolygon.id ? { ...item, ...response } : item)))
                } else {
                    getRois.setResponse((prev) => ([...(prev || []), response]))
                }
                setFormVisible(false)
                dispatch(setPolygons({ polygons: [newRoi], shouldUpdateHistory: false }))
            } else if (error) {
                handleErrorMsg(error)
            }
        }
    }

    const onEditRoi = (roi) => {
        const initialRoi = {
            ...roi,
            edit: true,
            isFinished: roi.points.length > 0,
            // from array of objects to array of strings (label_title filed)
            labels: roi.labels.map((label) => label.labelTitle),
            flattenedPoints: roi.points.flatMap((point) => [point.x, point.y]),
            colors: {
                vertexRadius: 2.5,
                lineColor: roi.color || '#0B9E34',
                fillColor: hexToRGBA(roi.color || '#0B9E34', '0.30'),
                vertexColor: COLORS.white,
                vertexStrokeWidth: 1,
            },
            label: roi.name,
            description: roi.description,
            userId: roi.user ? roi.user.id : undefined,
            type: roi.type,
        }
        dispatch(setPolygons({ polygons: [initialRoi], shouldUpdateHistory: false }))
        setOpen(true)
    }

    const handleDeletePolygon = async (polygonId) => {
        const { success, error } = await deleteRoi.request({
            url: `${ROI_LIST}/${polygonId}`,
        })

        if (success) {
            getRois.setResponse((prev) => prev.filter((roi) => roi.id !== polygonId))
            const updatedPolygons = polygons.filter((p) => p.id !== polygonId)
            dispatch(setPolygons({ polygons: updatedPolygons, shouldUpdateHistory: true }))
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const handleClose = () => {
        setCurrentPolygon(null)
        setFormVisible(false)
        setOpen(false)
        dispatch(setPolygons({ polygons: [], shouldUpdateHistory: false }))
        dispatch(setActivePolygonIndex(0))
    }

    const onSubmitLine = async (values) => {
        if (createLine.loading) return
        const linePoints = polygons[0].points.map((p) => ({ x: Math.round(p.x), y: Math.round(p.y) }))
        const directionPoints = polygons[1].points.map((p) => ({ x: Math.round(p.x), y: Math.round(p.y) }))

        const isEdit = polygons[0].edit || polygons[1].edit

        const { success, response, error } = await (isEdit ? updateLine : createLine).request({
            url: isEdit ? `${LINE_LIST}/${values.id}` : LINE_LIST,
            data: {
                type: values.type,
                camera_id: camera.id,
                name: values.label,
                description: values.description,
                dx1: directionPoints[0].x || 0,
                dy1: directionPoints[0].y || 0,
                dx2: directionPoints[1].x || 0,
                dy2: directionPoints[1].y || 0,
                x1: linePoints[0].x || 0,
                y1: linePoints[0].y || 0,
                x2: linePoints[1].x || 0,
                y2: linePoints[1].y || 0,
            },
        })

        if (success) {
            if (isEdit) {
                getLines.setResponse((prev) => prev
                    .map((item) => (item.id === values.id ? { ...item, ...response } : item)))
            } else {
                getLines.setResponse((prev) => ([...(prev || []), response]))
            }
            const { line, direction } = InitializeLineData(response)

            dispatch(setPolygons({ polygons: [line, direction], shouldUpdateHistory: true }))
            setFormVisible(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    const onEditLine = (data) => {
        const { line, direction } = InitializeLineData(data)
        dispatch(setPolygons({ polygons: [line, direction], shouldUpdateHistory: false }))
        setOpen(true)
    }

    const handleDeleteLine = async (lineId) => {
        const { success, error } = await deleteLine.request({
            url: `${LINE_LIST}/${lineId}`,
        })

        if (success) {
            dispatch(setPolygons({ polygons: [PolygonInitialState], shouldUpdateHistory: true }))
            getLines.setResponse((prev) => prev.filter((line) => line.id !== lineId))
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <PrimaryBtn
                onClick={() => setOpen(true)}
                Icon={AddCircle}
                styles={{ mx: 2, mt: 2 }}
                title={`Add ${section === 'line' ? 'line' : 'ROI'}`}
            />

            <Table
                paginated={false}
                cols={cols}
                rows={section === 'roi' ? rois : lines}
                renderItem={(item) => (
                    <RoiTableItem
                        key={item.id}
                        item={item}
                        onDelete={() => (section === 'roi' ? handleDeletePolygon(item.id) : handleDeleteLine(item.id))}
                        onEdit={section === 'roi' ? onEditRoi : onEditLine}
                        setOpen={setOpen}
                    />
                )}
            />

            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                sx={{ '& .MuiPaper-root': {
                    backgroundColor: 'transparent',
                    maxWidth: 'fit-content',
                    maxHeight: '100%',
                } }}
            >
                <DialogContent sx={{ padding: 0, borderRadius: '8px', backgroundColor: 'white' }}>
                    <div className="flex items-center justify-between py-1 pl-3 pr-1
                     bg-main-100 text-white text-lg font-medium"
                    >
                        <h3>
                            Create
                            {section === 'roi' ? 'ROI' : 'Line' }
                        </h3>

                        <IconButton onClick={handleClose}>
                            <Close htmlColor="white" />
                        </IconButton>
                    </div>

                    {section === 'roi' ? (
                        <Canvas
                            cameraId={camera.id}
                            maxPoints={5}
                            maxPolygons={2}
                            onSave={onSubmit}
                            loading={createRoi.loading}
                            setCurrentPolygon={setCurrentPolygon}
                            onDelete={handleDeletePolygon}
                            formVisible={formVisible}
                            setFormVisible={setFormVisible}
                            onClose={handleClose}
                            imageSrc={imageSrc}
                        />
                    ) : (
                        <LineCrossing
                            cameraId={camera.id}
                            maxPoints={2}
                            maxPolygons={2}
                            onSave={onSubmitLine}
                            loading={createLine.loading}
                            onDelete={handleDeleteLine}
                            formVisible={formVisible}
                            setFormVisible={setFormVisible}
                            onClose={handleClose}
                            imageSrc={imageSrc}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}
