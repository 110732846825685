import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MenuItem, Paper, Select } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import React from 'react'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const statuses = [
    {
        id: 'NEW',
        title: 'NEW',
    },
    {
        id: 'SELECTED',
        title: 'SELECTED',
    },
    {
        id: 'ARCHIVED',
        title: 'ARCHIVED',
    },
]

// eslint-disable-next-line import/prefer-default-export
export function GuestMessageFilter() {
    const { guestFilter, setGuestFilters } = useFilter()
    const statusOption = statuses.map((s) => ({ value: s.id,
        label: s.title }))
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? statusOption.find(
                            (v) => v.value === value,
                        )?.label : 'Choose status')}
                        sx={{ height: 37, width: '10rem' }}
                        value={guestFilter.status}
                        onChange={(e) => setGuestFilters({ status: e.target.value })}
                    >
                        {statusOption.length && statusOption.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        ))}
                    </Select>

                </div>
            </Paper>
        </LocalizationProvider>
    )
}
