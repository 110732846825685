import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Avatar } from '@mui/material'
import moment from 'moment'
import Canvas from './components/Canvas'
import { setPolygons, updatePolygonLabel, redo, undo } from '../../shared/store/polygonSlice'
import { BAZAAR_ROI,
    BAZAAR_ROI_BULK,
    BAZAAR_ROI_BY_SCAMERA,
    BAZAAR_SCAMERA_SNAPSHOT_HISTORY } from '../../shared/utils/urls'
import { useLoad, usePostRequest, useDeleteRequest } from '../../shared/hooks/requests'
import { hexToRGBA } from '../../shared/utils/string'
import { COLORS } from '../../shared/utils/colors'

function RoiPage() {
    const { id } = useParams()
    const dispatch = useDispatch()

    // eslint-disable-next-line no-unused-vars
    const [savedPolygons, setSavedPolygons] = useState([])
    const [currentPolygon, setCurrentPolygon] = useState(null)
    const [formVisible, setFormVisible] = useState(false)
    const [isDrawing, setIsDrawing] = useState(false) // <-- храним тут состояние рисования
    const { polygons } = useSelector((state) => state.polygon)

    // Модалка подтверждения отправки
    const [confirmationOpen, setConfirmationOpen] = useState(false)

    // Модалка выбора снапшота
    const [snapshotModalOpen, setSnapshotModalOpen] = useState(false)
    const [imageSrc, setImageSrc] = useState('')

    // Запрос для сохранения ROIs
    const createRoi = usePostRequest({ url: BAZAAR_ROI_BULK })
    // Delete ROI
    const deleteRoi = useDeleteRequest({ url: BAZAAR_ROI.replace('{id}', '') })

    // Запрос всех снапшотов камеры
    const getSnapshot = useLoad(
        { url: BAZAAR_SCAMERA_SNAPSHOT_HISTORY.replace('{id}', id) },
        [],
    )
    // Все снимки
    const snapshots = getSnapshot.response || []
    const sortedSnapshots = snapshots.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    // При первом рендере (или при обновлении snapshots) устанавливаем первый снимок
    useEffect(() => {
        if (snapshots.length > 0) {
            setImageSrc(snapshots[0].snapshotUrl)
        }
    }, [snapshots])

    // Запрос всех ROIs для камеры
    const getRois = useLoad(
        { url: BAZAAR_ROI_BY_SCAMERA.replace('{id}', id) },
        [],
    )
    const rois = getRois.response || []

    // Преобразуем загруженные ROIs в формат полигонов и сохраняем в Redux
    useEffect(() => {
        if (rois && Array.isArray(rois) && rois.length > 0) {
            const transformedPolygons = rois.map((roi) => {
                // Sort points by orderNumber
                const sortedPoints = [...roi.points].sort((a, b) => a.orderNumber - b.orderNumber)

                return {
                    id: roi.id,
                    label: roi.name,
                    description: roi.description || '',
                    points: sortedPoints,
                    isFinished: true,
                    shopId: roi.shopId,
                    colors: {
                        vertexRadius: 2.5,
                        lineColor: roi.color || '#0B9E34',
                        fillColor: hexToRGBA(roi.color || '#0B9E34', '0.30'),
                        vertexColor: COLORS.white,
                        vertexStrokeWidth: 1,
                    },
                    userId: roi.user_id,
                    type: roi.type,
                    flattenedPoints: sortedPoints.flatMap((p) => [p.x, p.y]),
                }
            })
            dispatch(setPolygons({ polygons: transformedPolygons, shouldUpdateHistory: false }))
        } else {
            dispatch(setPolygons({ polygons: [], shouldUpdateHistory: false }))
        }
    }, [rois, dispatch])

    // ========= Горячие клавиши =========
    useEffect(() => {
        const handleKeyDown = (e) => {
            // Enter -> открыть подтверждение отправки
            if (e.key === 'Enter') {
                e.preventDefault()
                setConfirmationOpen(true)
            }
            // Tab -> открыть выбор снапшота
            if (e.key === 'Tab') {
                e.preventDefault()
                setSnapshotModalOpen(true)
            }
        }
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    // Подтверждение отправки ROIs
    const handleConfirm = async () => {
        setConfirmationOpen(false)
        // Округляем координаты точек
        const filteredPolygons = polygons.map((p) => {
            const { label, points, color, shopId } = p
            const roundedPoints = points.map((pt) => ({
                ...pt,
                x: Math.round(pt.x),
                y: Math.round(pt.y),
            }))
            return {
                name: label,
                points: roundedPoints,
                color,
                shopId,
            }
        })
        const { response, success, error } = await createRoi.request({
            data: {
                smartCameraId: id,
                rois: filteredPolygons,
            },
        })
        if (success) {
            console.log('ROIs sent successfully:', response)
            setFormVisible(false)
        } else if (error) {
            console.log('Error:', error)
        }
    }

    // Отмена отправки
    const handleCancel = () => {
        setConfirmationOpen(false)
    }

    // Сохранение ROI
    const onSubmit = async (values) => {
        if (currentPolygon) {
            console.log('Current Polygon:', values)
            const updatedPolygon = { ...currentPolygon, ...values }
            const updatedPolygons = polygons.map((p) => (p.id === updatedPolygon.id ? updatedPolygon : p))
            dispatch(updatePolygonLabel({ id: updatedPolygon.id, info: updatedPolygon }))
            dispatch(setPolygons({ polygons: updatedPolygons, shouldUpdateHistory: true }))
            setFormVisible(false)
        }
    }

    // Удаление полигона
    const handleDeletePolygon = (polygonId) => {
        console.log('Polygon Deleted:', polygonId)
        deleteRoi.request({ url: BAZAAR_ROI.replace('{id}', polygonId) })

        const updatedPolygons = polygons.filter((p) => p.id !== polygonId)
        dispatch(setPolygons({ polygons: updatedPolygons, shouldUpdateHistory: true }))
        setSavedPolygons((prev) => prev.filter((p) => p.id !== polygonId))
    }

    // Закрытие формы
    const handleClose = () => {
        setFormVisible(false)
        setCurrentPolygon(null)
    }

    // Клик на скриншот: сразу меняем imageSrc и закрываем диалог
    const handleSnapshotClick = (snapshotUrl) => {
        setImageSrc(snapshotUrl)
        setSnapshotModalOpen(false)
    }

    // Обработка Undo/Redo
    const handleUndo = () => {
        dispatch(undo())
    }
    const handleRedo = () => {
        dispatch(redo())
    }

    // Переключаем режим рисования
    const toggleDrawing = (forceValue) => {
        if (typeof forceValue === 'boolean') {
            setIsDrawing(forceValue)
        } else {
            setIsDrawing((prev) => !prev)
        }
    }

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            {/* Кнопки для открытия модалок и т.д. */}
            <div style={{ margin: '10px', display: 'flex', gap: '8px', alignItems: 'center' }}>

                <Button
                    variant="outlined"
                    onClick={() => setSnapshotModalOpen(true)}
                >
                    Choose Snapshot
                </Button>

                {/* Undo/Redo */}
                <Button variant="outlined" onClick={handleUndo}>
                    Undo (CTRL+Z)
                </Button>
                <Button variant="outlined" onClick={handleRedo}>
                    Redo (CTRL+Y)
                </Button>

                {/* Draw-кнопка */}
                <Button
                    variant={isDrawing ? 'contained' : 'outlined'} // Если рисуем, делаем "contained"
                    onClick={toggleDrawing}
                >
                    {isDrawing ? 'Stop Drawing' : 'Draw'}
                </Button>

                {/* Save */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setConfirmationOpen(true)}
                >
                    Save
                </Button>

            </div>

            {/* Canvas, передаём isDrawing */}
            <Canvas
                cameraId="static-camera-id"
                imageSrc={imageSrc}
                maxPoints={8}
                maxPolygons={100}
                onSave={onSubmit}
                loading={false}
                setCurrentPolygon={setCurrentPolygon}
                onDelete={handleDeletePolygon}
                formVisible={formVisible}
                setFormVisible={setFormVisible}
                onClose={handleClose}
                isDrawing={isDrawing}
                toggleDrawing={toggleDrawing}
            />

            {/* Диалог подтверждения отправки */}
            <Dialog
                open={confirmationOpen}
                onClose={handleCancel}
            >
                <DialogTitle>Send ROIs</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to send ROIs?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="error">Cancel</Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Диалог выбора снапшота */}
            <Dialog
                open={snapshotModalOpen}
                onClose={() => setSnapshotModalOpen(false)}
                fullWidth
                sx={{
                    height: '600px',
                }}
            >
                <DialogTitle>Select a Snapshot</DialogTitle>
                <DialogContent dividers>
                    {snapshots.length === 0 ? (
                        <DialogContentText>No snapshots available.</DialogContentText>
                    ) : (
                        <List>

                            {sortedSnapshots.map((snap, i) => (
                                <ListItem
                                    button
                                    key={snap.id || i}
                                    onClick={() => handleSnapshotClick(snap.snapshotUrl)}
                                >
                                    <ListItemAvatar>
                                        <Avatar
                                            variant="square"
                                            src={snap.snapshotUrl}
                                            alt={`Snapshot #${i + 1}`}
                                            sx={{ width: 60, height: 60 }}
                                        />
                                    </ListItemAvatar>
                                    <div className="flex flex-col items-start gap-[5px]">
                                        <ListItemText
                                            primary={`Snapshot #${i + 1}`}
                                            secondary={moment(snap.createdAt).format('DD.MM.YYYY HH:mm')}
                                            sx={{ marginLeft: '10px' }}
                                        />
                                    </div>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSnapshotModalOpen(false)}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RoiPage
