import React, { useState } from 'react'
import { Modal } from '@mui/material'
import moment from 'moment/moment'
import noPhoto from '../../../static/images/no-photo.png'

const cols = [
    { id: 1, title: 'ID' },
    { id: 2, title: 'Photo' },
    { id: 3, title: 'isActive' },
    { id: 4, title: 'Distance' },
    { id: 5, title: 'Version' },
    { id: 6, title: 'CreatedAt' },
]

export function SimilarityModal({ data }) {
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <>
            <div className="flex items-center justify-center">
                <button
                    onClick={handleOpen}
                    className="py-[10px] px-[15px]
        bg-main-300 text-white rounded-[15px]"
                >
                    Similarity
                </button>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                className="flex items-center justify-center"
            >
                <div className="w-[60%] py-[10px] px-[10px] rounded-[10px] bg-white h-auto flex flex-col">
                    {/* Заголовки + горизонтальный скролл */}
                    <div className="overflow-x-auto w-full border rounded-md">
                        <div className="h-[100%]">
                            {/* Заголовок */}
                            <div className="flex border-b">
                                {cols.map((col) => (
                                    <div key={col.id} className="p-2 flex-1 min-w-[100px] text-center font-bold">
                                        {col.title}
                                    </div>
                                ))}
                            </div>

                            <div className="w-[100%] h-[360px] overflow-scroll">
                                { data.length > 0 ? (
                                    data.map((items) => (
                                        <div key={items.id} className="flex items-center border-b">
                                            {/* Similar ID */}
                                            <div className="p-2 flex-1 min-w-[100px] text-center">
                                                {items.similarIdentityId}
                                            </div>

                                            {/* Photo */}
                                            <div className="p-2 flex-1 flex items-center justify-center min-w-[100px] text-center">
                                                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                                                    <img
                                                        src={noPhoto || data.similarImageUrl}
                                                        alt="Main"
                                                        className="w-[100%] h-[100%] object-cover"
                                                        onClick={() => handleOpen(data.imageUrl)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </div>
                                            </div>

                                            {/* is Active */}
                                            <div className="p-2 flex-1 min-w-[100px] text-center">
                                                {items.isActive ? (items.isActive === false ? 'False' : 'True') : 'null'}
                                            </div>

                                            {/* distance */}
                                            <div className="p-2 flex-1 min-w-[100px] text-center">
                                                <span>{items.distance}</span>
                                            </div>

                                            {/* Version */}
                                            <div className="p-2 flex-1 min-w-[100px] text-center">
                                                {items.version}
                                            </div>
                                            <div className="p-2 flex-1 min-w-[100px] text-center">
                                                {moment(items.createdAt).format('YYYY-MM-DD HH:mm')}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="p-4 text-center">Empty</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
