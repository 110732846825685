/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'
import {DetailModal} from './DetailsModal'

export function SystemIntegrationTableItems({ item }) {
    const totalDifference = item.totalCount - (item.totalKids + item.totalEdus)

    return (
        <>
            <TableCell>
                <span className="text-blue-700">{item.id}</span>
            </TableCell>
            <TableCell>
                {item.mttId}
            </TableCell>
            <TableCell>
                {item.name}
            </TableCell>
            <TableCell>
                <span className="text-blue-700">{item.totalCount}</span>
            </TableCell>
            <TableCell>
                <span className="text-blue-700">{item.kidsCount}</span>
            </TableCell>
            <TableCell>
                <span className="text-blue-700">{item.employeesCount}</span>
            </TableCell>
            <TableCell>
                <span className="text-red-600">{totalDifference}</span>
            </TableCell>
            <TableCell>
                {item.totalKids}
            </TableCell>
            <TableCell>
                {item.acceptedKids}
            </TableCell>
            <TableCell>
                {item.rejectedKids}
            </TableCell>
            <TableCell>
                {item.waitingKids}
            </TableCell>
            <TableCell>
                {item.totalEdus}
            </TableCell>
            <TableCell>
                {item.acceptedEdus}
            </TableCell>
            <TableCell>
                {item.rejectedEdus}
            </TableCell>
            <TableCell>
                {item.waitingEdus}
            </TableCell>
            <TableCell>
                <DetailModal id={item.id} />
            </TableCell>
        </>
    )
}
