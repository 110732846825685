/* eslint-disable */
import {
    Table as MuiTable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
    Box,
    CircularProgress,
} from '@mui/material'
import React, { useState } from 'react'
import TablePagination from '@mui/material/TablePagination'
import uuid from 'react-uuid'
import { EmptyFolderIcon } from '../../components/svgs/Svgs'
import { COLORS } from '../utils/colors'

export default function Table({
                                  cols,
                                  loading,
                                  renderItem,
                                  onSelectAll = () => {},
                                  onSelect = () => {},
                                  disableSelectAll = false,
                                  rowsPerPage,
                                  page,
                                  paginated = true,
                                  totalCount = 0,
                                  onPageChange = () => {},
                                  onRowsPerPageChange = () => {},
                                  withCheckbox = false,
                                  rows = [],
                              }) {
    const [selected, setSelected] = useState([])

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n)
            setSelected(newSelected)
            if (typeof onSelectAll === 'function') {
                onSelectAll(newSelected)
            }
        } else {
            onSelectAll([])
            setSelected([])
        }
    }

    const handleSelectOne = (event, item) => {
        let newSelected = []

        if (event.target.checked) {
            newSelected = [...selected, item]
        } else {
            newSelected = selected.filter((v) => v.id !== item.id)
        }
        setSelected(newSelected)

        if (typeof onSelect === 'function') {
            if (newSelected.length === 0) {
                onSelect([])
            } else {
                onSelect(item)
            }
        }
    }

    const isSelected = (item) => selected.some((v) => v.id === item.id)

    return (
        <TableContainer className="flex flex-col bg-white">
            <MuiTable>
                <TableHead>
                    <TableRow>
                        {withCheckbox && !disableSelectAll ? (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={
                                        selected.length > 0 && selected.length < rows.length
                                    }
                                    checked={
                                        rows.length > 0 && selected.length === rows.length
                                    }
                                    onChange={handleSelectAllClick}
                                />
                            </TableCell>
                        ) : null}

                        {cols &&
                            cols.map((c) => {
                                // Если у колонки есть onHeaderClick, делаем её кликабельной
                                const handleClick =
                                    typeof c.onHeaderClick === 'function'
                                        ? () => c.onHeaderClick(c)
                                        : undefined

                                // Объединяем стили из c.sx с дополнительными
                                const cellSx = {
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    padding: c.padding,
                                    whiteSpace: 'nowrap',
                                    color: c.color,
                                    maxWidth: 150,
                                    cursor: handleClick ? 'pointer' : 'inherit',
                                    ...(c.sx || {}),
                                }

                                return (
                                    <TableCell
                                        key={c.id}
                                        align={c.align}
                                        width={c.width}
                                        sx={cellSx}
                                        onClick={handleClick}
                                    >
                                        {c.title}
                                    </TableCell>
                                )
                            })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {!loading &&
                        rows.map((item, i) => (
                            <TableRow
                                key={`${uuid()}-${item.id}`}
                                sx={{
                                    '& .MuiTableCell-root': {
                                        paddingLeft: withCheckbox && !disableSelectAll ? '4px' : '16px',
                                        paddingRight: '16px',
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                        height: '56px',
                                        cursor: 'pointer',
                                    },
                                }}
                            >
                                {withCheckbox ? (
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isSelected(item)}
                                            onChange={(e) => handleSelectOne(e, item)}
                                        />
                                    </TableCell>
                                ) : null}

                                {renderItem(item, i)}
                            </TableRow>
                        ))}
                </TableBody>
            </MuiTable>

            {loading && (
                <Box className="w-full h-[30vh] flex items-center justify-center">
                    <CircularProgress htmlcolor={COLORS.mainBlueBg} />
                </Box>
            )}

            {!loading && !rows.length && (
                <Box className="w-full h-[30vh] flex items-center justify-center">
                    <EmptyFolderIcon />
                </Box>
            )}

            {paginated && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                />
            )}
        </TableContainer>
    )
}
