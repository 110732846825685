/* eslint-disable */
import React, { useMemo } from 'react'

// Предположим, координаты приходят из бэка относительно 1920×1080
const BASE_WIDTH = 1920
const BASE_HEIGHT = 1080

function hexToRGBA(hexColor, alpha = 1) {
    let hex = hexColor.replace('#', '')

    // Укорачиваем #ABC → #AABBCC
    if (hex.length === 3) {
        hex = hex.split('').map((c) => c + c).join('')
    }

    const r = parseInt(hex.substring(0, 2), 16)
    const g = parseInt(hex.substring(2, 4), 16)
    const b = parseInt(hex.substring(4, 6), 16)

    return `rgba(${r},${g},${b},${alpha})`
}

/**
 * @param {Array} rois - массив ROI, где каждый ROI имеет:
 *   { id, name, shop_id, points: [{ x, y, order_number }], color? }
 * @param {number} width - реальная ширина overlay (соответствует размеру плеера)
 * @param {number} height - реальная высота overlay (соответствует размеру плеера)
 */
export default function RoiOverlay({ rois = [], width = 640, height = 360 }) {
    // Масштабируем точки из base (1920×1080) в текущие width×height
    const polygons = useMemo(() => {
        return rois.map((roi) => {
            // Сортируем точки
            const sortedPoints = [...roi.points].sort(
                (a, b) => a.order_number - b.order_number
            )

            // Преобразуем каждую точку, умножая на (width/BASE_WIDTH), (height/BASE_HEIGHT)
            const scaledPoints = sortedPoints.map((pt) => {
                const sx = (pt.x / BASE_WIDTH) * width
                const sy = (pt.y / BASE_HEIGHT) * height
                return { x: sx, y: sy }
            })

            // Собираем строку для <polygon points="x1,y1 x2,y2 ...">
            const polygonPoints = scaledPoints
                .map((p) => `${p.x},${p.y}`)
                .join(' ')

            // Цвет обводки
            const strokeColor = roi.color || '#2ba7ff'

            // Координаты для подписи (center), возьмём средний X/Y
            const avgX =
                scaledPoints.reduce((acc, pt) => acc + pt.x, 0) / scaledPoints.length
            const avgY =
                scaledPoints.reduce((acc, pt) => acc + pt.y, 0) / scaledPoints.length

            return {
                id: roi.id,
                name: roi.name || 'ROI',
                shopId: roi.shopId,
                polygonPoints,
                stroke: strokeColor,
                avgX,
                avgY,
            }
        })
    }, [rois, width, height])

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width,
                height,
                pointerEvents: 'none',
                overflow: 'hidden'
            }}
        >
            <svg
                width={width}
                height={height}
                style={{ display: 'block' }}
            >
                {polygons.map((poly) => (
                    <React.Fragment key={poly.id}>
                        {/* Сам полигон */}
                        <polygon
                            points={poly.polygonPoints}
                            stroke={poly.stroke}
                            fill={hexToRGBA(poly.stroke, 0.3)} // fill с прозрачностью 0.3
                            strokeWidth={2}
                        />

                        {/* Группа для фона и текста */}
                        <g transform={`translate(${poly.avgX}, ${poly.avgY})`}>
                            {/* Прямоугольник позади текста (пример) */}
                            <rect
                                x={-20}
                                y={-14}
                                width={40}
                                height={28}
                                fill="rgba(0, 0, 0, 0.6)"
                                rx={6}
                                ry={6}
                            />
                            {/* Подпись (название + shopId) */}
                            <text
                                x={0}
                                y={0}
                                fill="#ffffff"
                                fontSize={12}
                                textAnchor="middle"
                                dominantBaseline="middle"
                                style={{ pointerEvents: 'none' }}
                            >
                               {poly.shopId}
                            </text>
                        </g>
                    </React.Fragment>
                ))}
            </svg>
        </div>
    )
}
