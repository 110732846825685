/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { MenuItem, FormControl, Select, Paper, InputLabel } from '@mui/material'
import { debounce } from 'lodash'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { DISTRICTS_LIST,
    ALLOWED_REGIONS,
    TENANT_ENTITY_FILTER,
    TENANT_ENTITY_CUSTOMER_FILTER } from '../../../shared/utils/urls'

import SelectInput from '../../../shared/ui/SelectInput'

const Boolean = [
    {id: '', clientName: '-'},
    { id: 'True', clientName: 'True' },
    { id: 'False', clientName: 'False' },
]

const Event = [
    {id: '', clientName: '-'},
    { id: 'enter', clientName: 'enter' },
    { id: 'exit', clientName: 'exit' },
]

const identityGroupOptions = [
    {
      value: '',
      label: '-',
    },
    {
        value: 0,
        label: 'Kids',
    },
    {
        value: 1,
        label: 'Staff',
    },
]

const uniqueOptions = [
    {
      value: '',
      label: '-'
    },
    {
        value: true,
        label: 'True',
    },
    {
        value: false,
        label: 'False',
    }
]

export default function AttendanceAnalyticsFilter() {
    const [searchTerm, setSearchTerm] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const { attendaceFilter, setAttendanceFilters } = useFilter()
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: attendaceFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []

    const regionsOption = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOption = districts.map((district) => ({ value: district.id, label: district.name }))
    const eventOption = Event.map((event) => ({ value: event.id, label: event.clientName }))
    const mobileOption = Boolean.map((boolean) => ({ value: boolean.id, label: boolean.clientName }))

    const { dateFilter, setDateFilter } = useFilter()

    const userType = localStorage.getItem('userType')
    const entityListUrl = userType === 'tenant_admin' ? TENANT_ENTITY_FILTER : TENANT_ENTITY_CUSTOMER_FILTER

    const getAllEntities = useGetRequest({ url: entityListUrl,
        params:
            { search: searchTerm, regionId: attendaceFilter.region, districtId: attendaceFilter.district } }, [])
    const entities = getAllEntities.response ? getAllEntities.response : []
    const options = entities ? entities.map((e) => ({ value: e.id,
        label: e.name,
        col1:
        e.name,
        col2: e.region.name,
        col3: e.district.name })) : []

    useEffect(() => {
        if (attendaceFilter.region) {
            getDistricts.request()
        }
    }, [attendaceFilter.region])

    useEffect(() => {
        // Trigger request if searchTerm is not `null` (will run for empty string too)
        if (searchTerm !== null || attendaceFilter.district) {
            getAllEntities.request()
        }
    }, [searchTerm, attendaceFilter.district])

    const handleSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 300),
        [], // only create the debounce function once
    )

    // Handle search input changes
    const onSearchChange = (value) => {
        setSearchValue(value)
        handleSearch(value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="w-[100%] flex items-center gap-2">
                    {userType === 'tenant_admin' ? (
                        <>
                            <FormControl sx={{ minWidth: 100 }} size="small">
                                <InputLabel id="region-label">Region</InputLabel>
                                <Select
                                    labelId="region-label"
                                    id="region-select"
                                    label="Region"
                                    disabled={!regionsOption.length}
                                    value={attendaceFilter.region}
                                    onChange={(e) => {setAttendanceFilters({ region: e.target.value, district: '', entity: '' })}}
                                    variant="outlined"
                                >
                                    {!loadRegions.loading && regionsOption.length ? regionsOption.map((menuItem) => (
                                        <MenuItem key={menuItem.value} value={menuItem.value}>
                                            {menuItem?.label}
                                        </MenuItem>
                                    )) : loadRegions.loading ? (
                                        <MenuItem>
                                            <Skeleton width="50%" height={30} />
                                        </MenuItem>
                                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                                </Select>
                            </FormControl>

                            <FormControl sx={{ minWidth: 100 }} size="small">
                                <InputLabel id="district-label">District</InputLabel>
                                <Select
                                    labelId="district-label"
                                    id="district-select"
                                    label="Region"
                                    disabled={!districtOption.length}
                                    value={attendaceFilter.district}
                                    onChange={(e) => setAttendanceFilters({ district: e.target.value, entity: '' })}
                                    variant="outlined"
                                >
                                    {!getDistricts.loading && districtOption.length ? districtOption.map((menuItem) => (
                                        <MenuItem key={menuItem.value} value={menuItem.value}>
                                            {menuItem?.label}
                                        </MenuItem>
                                    )) : loadRegions.loading ? (
                                        <MenuItem>
                                            <Skeleton width="50%" height={30} />
                                        </MenuItem>
                                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                                </Select>
                            </FormControl>
                            <SelectInput
                                styles={{
                                    minWidth: '12rem',
                                    '& .MuiInputBase-root': {
                                        minHeight: '30px', // Set minimum height for container
                                        padding: '4px 8px', // Adjust padding for a compact look
                                    },
                                    '& .MuiSelect-select': {
                                        padding: '4px 8px', // Adjust padding inside the select to reduce height
                                        display: 'flex',
                                        alignItems: 'center', // Vertically center text
                                        minHeight: '20px', // Set the desired minimum height here
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        minHeight: '30px', // Match the outline to the container height
                                    },
                                    '& .MuiInputLabel-root': {
                                        top: '-7px', // Move label slightly upward
                                    },
                                }}
                                variant="outlined"
                                name="tenantEntityId"
                                label="Choose entity"
                                selectPlaceholder="Choose entity"
                                onChange={(e) => setAttendanceFilters({ entity: e.target.value })}
                                value={attendaceFilter.entity}
                                menuItems={options}
                                loading={attendaceFilter.loading}
                                searchable
                                placeholder="Search entities..."
                                onSearch={onSearchChange}
                                searchValue={searchValue}
                                disabled={!attendaceFilter.district}
                                tableMode
                            />
                        </>
                    ) : null}
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="unique-label">Unique</InputLabel>
                        <Select
                            labelId="unique-label"
                            id="unique-select"
                            label="Unique"
                            disabled={!uniqueOptions.length}
                            value={attendaceFilter.unique}
                            onChange={(e) => setAttendanceFilters({ unique: e.target.value })}
                            variant="outlined"
                        >
                            {uniqueOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }} size="small">
                        <InputLabel id="event-type-label">Event Type</InputLabel>
                        <Select
                            labelId="event-type-label"
                            id="event-type-select"
                            label="Event Type"
                            disabled={!eventOption.length}
                            value={attendaceFilter.eventType}
                            onChange={(e) => setAttendanceFilters({ eventType: e.target.value })}
                            variant="outlined"
                        >
                            {eventOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="event-type-label">Choose Mobile</InputLabel>
                        <Select
                            labelId="event-type-label"
                            id="event-type-select"
                            label="Choose Mobile"
                            disabled={!mobileOption.length}
                            value={attendaceFilter.mobile}
                            onChange={(e) => setAttendanceFilters({ mobile: e.target.value })}
                            variant="outlined"
                        >
                            {mobileOption.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 150 }} size="small">
                        <InputLabel id="identity-group-label">Identity Type</InputLabel>
                        <Select
                            labelId="identity-group-label"
                            id="event-type-select"
                            label="Identity Type"
                            disabled={!identityGroupOptions.length}
                            value={attendaceFilter.identityGroup}
                            onChange={(e) => setAttendanceFilters({ identityGroup: e.target.value })}
                            variant="outlined"
                        >
                            {identityGroupOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="flex flex-col items-start gap-[6px]">
                        <input
                            type="text"
                            className="w-[150px] h-[37px] border p-[10px] rounded-[6px]"
                            placeholder="Comp score"
                            value={attendaceFilter.consumer_from}
                            onChange={(e) => setAttendanceFilters({ consumer_from: e.target.value })}
                        />
                    </div>

                    {attendaceFilter.consumer_from !== '' ? (
                        <div className="flex flex-col items-start gap-[6px]">
                            <input
                                type="text"
                                className="w-[150px] h-[37px] border p-[10px] rounded-[6px]"
                                placeholder="00"
                                value={attendaceFilter.consumer_to}
                                onChange={(e) => setAttendanceFilters({ consumer_to: e.target.value })}
                            />
                        </div>
                    ) : null}
                    <div className="flex gap-2">
                        {/* Start Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="Start Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null}
                                onChange={(e) => {
                                    const formattedStartDate = e ? moment(e).format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        startPeriod: formattedStartDate,
                                        endPeriod: prev.endPeriod && moment(formattedStartDate).isBefore(moment(prev.endPeriod)) ? prev.endPeriod : formattedStartDate, // Reset endPeriod if it’s before startPeriod
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                            />
                        </div>

                        {/* End Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="End Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.endPeriod ? moment(dateFilter.endPeriod) : null}
                                onChange={(e) => {
                                    const formattedEndDate = e ? moment(e).format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        endPeriod: formattedEndDate,
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                                minDate={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null} // Ensure end date is after start date
                            />
                        </div>
                    </div>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
