import React from 'react'
import cn from 'classnames'
import moment from 'moment'
import { css, StyleSheet } from 'aphrodite'
import { Grid } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import ModalHeader from '../../../components/common/ModalHeader'

export default function AnalyticsModal({ item, modalRef }) {
    const cameraName = item.camera_name || '-'
    const modalTitle = `Notification > ${cameraName}` || '-'
    const frameImage = item.frame_image
    const date = moment(item.timestamp).format('DD.MM.YYYY')
    const time = moment(item.timestamp).format('HH:mm')
    return (
        <div className={css(s.modalWrapper)}>
            <ModalHeader title={modalTitle} onClose={() => modalRef.current.close()} />
            <div className={css(s.modalBody)}>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <img
                            src={frameImage}
                            alt="camera"
                            className={cn(css(s.notificationImage))}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <div className={cn(css(s.notificationBodyWrapper))}>
                            <div>
                                <span className={cn(css(s.titleText))}>Camera: </span>
                                <span className={cn(css(s.opacityText))}>{cameraName}</span>
                            </div>
                            <div>
                                <span className={cn(css(s.titleText))}>Date: </span>
                                <span className={cn(css(s.opacityText))}>{date}</span>
                            </div>
                            <div>
                                <span className={cn(css(s.titleText))}>Time: </span>
                                <span className={cn(css(s.opacityText))}>{time}</span>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    modalWrapper: {
        width: '80vw',
        maxWidth: '80vw',
        backgroundColor: 'white',
        borderRadius: '10px',
    },
    modalBody: {
        padding: '0 30px 20px 30px',
    },
    notificationImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
    },
    notificationBodyWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0px',
        marginTop: '30px',
    },
    titleText: {
        color: COLORS.black,
        fontWeight: 500,
        fontSize: '18px',
    },
    opacityText: {
        color: '#878787',
        fontSize: '18px',
    },
})
