import React, { useCallback, useEffect, useState } from 'react'
import { MenuItem, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { debounce } from 'lodash'
import Paper from '@mui/material/Paper'
import { useGetRequest, useLoad } from '../hooks/requests'
import { useFilter } from '../../app/contexts/FilterContextProvider'
import { ALLOWED_REGIONS, DISTRICTS_LIST, TENANT_ENTITY_FILTER } from '../utils/urls'
import SelectInput from '../ui/SelectInput'

export default function TenantEntityFilter({ variant = 'filter', setFieldValue = () => {} }) {
    const { identityFilter, setIdentityFilters } = useFilter()
    const entityListUrl = TENANT_ENTITY_FILTER
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: identityFilter.rId } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = [
        { value: '', label: '-' },
        ...regions.map((region) => ({ value: region.id, label: region.name })),
    ]
    const districtOptions = districts.length ? [
        { value: '', label: '-' }, // Default option
        ...districts.map((district) => ({ value: district.id, label: district.name })),
    ] : []
    const [searchTerm, setSearchTerm] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const getAllEntities = useGetRequest({ url: entityListUrl,
        params: { search: searchTerm, regionId: identityFilter.rId, districtId: identityFilter.dId } }, [])
    const data = getAllEntities.response ? getAllEntities.response : []
    const options = data ? data.map((e) => ({ value: e.id,
        label: e.name,
        col1: e.name,
        col2: e.region.name,
        col3: e.district.name })) : []

    const selectStyle = {
        modal: { height: 37, width: '100%' },
        filter: { height: 37, width: '10rem' },
    }
    const selectVariant = {
        modal: 'standard',
        filter: 'outlined',
    }

    // Debounce the onSearch callback
    const handleSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 300),
        [], // only create the debounce function once
    )

    // Handle search input changes
    const onSearchChange = (value) => {
        setSearchValue(value)
        handleSearch(value)
    }
    useEffect(() => {
        // Trigger request if searchTerm is not `null` (will run for empty string too)
        if (searchTerm !== null || identityFilter.dId) {
            getAllEntities.request()
        }
    }, [searchTerm, identityFilter.dId])

    useEffect(() => {
        // Reset district and entity selection if region is deselected
        if (identityFilter.rId === '') {
            setIdentityFilters({ dId: '', tenantEntityId: '' })
        } else {
            setIdentityFilters({ dId: '' }) // Reset district when a region is selected
            getDistricts.request()
        }
    }, [identityFilter.rId])

    return (
        <Paper
            sx={{
                boxShadow: variant === 'modal' ? 'none' : undefined,
                border: variant === 'modal' ? 'none' : undefined,
            }}
            square
            className={`flex flex-col gap-2.5 ${variant === 'filter' ? 'p-3' : ''}`}
        >
            {
                variant === 'filter' && (
                    <h3 className="text-m text-black font-500">Filter</h3>
                )
            }

            <div className={`flex items-center gap-2 ${variant === 'modal' ? 'flex-col gap-4' : ''}`}>
                <Select
                    variant={selectVariant[variant]}
                    displayEmpty
                    renderValue={(value) => (value ? regionOptions.find((v) => v.value === value)?.label : 'Choose region')}
                    sx={selectStyle[variant]}
                    value={identityFilter.rId}
                    onChange={(e) => setIdentityFilters({ rId: e.target.value })}
                >
                    {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                        <MenuItem key={menuItem.value} value={menuItem.value}>
                            {menuItem?.label}
                        </MenuItem>
                    )) : loadRegions.loading ? (
                        <MenuItem>
                            <Skeleton width="50%" height={30} />
                        </MenuItem>
                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                </Select>

                <Select
                    variant={selectVariant[variant]}
                    displayEmpty
                    renderValue={(value) => (value ? districtOptions.find((v) => v.value === value)?.label : 'Choose district')}
                    sx={selectStyle[variant]}
                    value={identityFilter.dId}
                    disabled={!districtOptions.length || identityFilter.rId === ''}
                    onChange={(e) => setIdentityFilters({ dId: e.target.value })}
                >
                    {!getDistricts.loading && identityFilter.rId !== '' && districtOptions.length ? districtOptions.map((menuItem) => (
                        <MenuItem key={menuItem.value} value={menuItem.value}>
                            {menuItem?.label}
                        </MenuItem>
                    )) : getDistricts.loading ? (
                        <MenuItem>
                            <Skeleton width="50%" height={30} />
                        </MenuItem>
                    ) : <MenuItem value="">Nothing Found</MenuItem>}
                </Select>
                <SelectInput
                    styles={variant === 'modal' ? { width: '100%' } : {
                        minWidth: '12rem',
                        '& .MuiInputBase-root': {
                            minHeight: '30px', // Set minimum height for container
                            padding: '4px 8px', // Adjust padding for a compact look
                        },
                        '& .MuiSelect-select': {
                            padding: '4px 8px', // Adjust padding inside the select to reduce height
                            display: 'flex',
                            alignItems: 'center', // Vertically center text
                            minHeight: '20px', // Set the desired minimum height here
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            minHeight: '30px', // Match the outline to the container height
                        },
                        '& .MuiInputLabel-root': {
                            top: '-7px', // Move label slightly upward
                        },
                    }}
                    variant={selectVariant[variant]}
                    name="tenantEntityId"
                    label="Choose entity"
                    selectPlaceholder="Choose entity"
                    onChange={(e) => {
                        setIdentityFilters({ tenantEntityId: e.target.value })
                        setFieldValue('tenantEntityId', e.target.value)
                    }}
                    value={identityFilter.tenantEntityId}
                    menuItems={identityFilter.dId ? options : []}
                    loading={getAllEntities.loading}
                    searchable
                    placeholder="Search entities..."
                    onSearch={onSearchChange}
                    searchValue={searchValue}
                    tableMode
                />
            </div>
        </Paper>
    )
}
