import React, { useState } from 'react'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Add, Close } from '@mui/icons-material'

import { usePostRequest } from '../../../shared/hooks/requests'
import { TENANT_ENTITIES } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { COLORS } from '../../../shared/utils/colors'
import EntityForm from '../components/EntityForm'

export default function Create({ setData, parentOptions, hierarchyLevel }) {
    const [open, setOpen] = useState(false)
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const createEntity = usePostRequest({ url: TENANT_ENTITIES })

    const create = async (values) => {
        if (createEntity.loading || !values) return

        const { response, success, error } = await createEntity.request({ data: {
            ...values,
            hierarchyLevel,
            parentId: hierarchyLevel === 1 ? undefined : values.parentId,
        } })

        if (success) {
            setData((prev) => {
                const addEntity = (items, newEntity) => items.map((item) => {
                    if (item.id === newEntity.parentId) {
                        return {
                            ...item,
                            children: item.children ? [...item.children, newEntity] : [newEntity],
                        }
                    }
                    if (item.children) {
                        return {
                            ...item,
                            children: addEntity(item.children, newEntity),
                        }
                    }
                    return item
                })

                if (!parentOptions) {
                    return {
                        ...prev,
                        items: [...prev.items, response],
                    }
                }

                return {
                    ...prev,
                    items: addEntity(prev.items, response),
                }
            })
            showMessage('Successfully created', 'success-msg')
            setOpen(false)
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <Add fontSize="small" htmlColor={COLORS.gray} />
            </IconButton>

            <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
                <div className="flex items-center justify-between py-1 pl-3 pr-1
                 bg-main-100 text-white text-lg font-medium"
                >
                    <Typography>
                        Create
                        {' '}
                        {hierarchyLevel === 1 ? 'Head quarter' : hierarchyLevel === 2 ? 'Section' : 'Branch' }
                    </Typography>

                    <IconButton onClick={() => setOpen(false)}>
                        <Close htmlColor="white" />
                    </IconButton>
                </div>

                <DialogContent style={{ padding: 0 }}>
                    <EntityForm
                        hierarchyLevel={hierarchyLevel}
                        parentOptions={parentOptions}
                        loading={createEntity.loading}
                        onSubmit={create}
                        onClose={() => setOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}
