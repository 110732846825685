/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MenuItem, Paper, Select } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment/moment'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import { GET_PATHS } from '../../../shared/utils/urls'

const codes = [
    {
        value: '',
        label: '-'
    },
    {
        value: '400',
        label: '400'
    },
    {
        value: '401',
        label: '401',
    },
    {
        value: '403',
        label: '403',
    },
    {
        value: '404',
        label: '404',
    },
    {
        value: '405',
        label: '405',
    },
    {
        value: '408',
        label: '408',
    },
    {
        value: '410',
        label: '410',
    },
    {
        value: '422',
        label: '422',
    },
    {
        value: '425',
        label: '425',
    },
    {
        value: '500',
        label: '500',
    },
    {
        value: '502',
        label: '502'
    }
]

const methods = [
    {
        value: '',
        label: '-'
    },
    {
        value: 'GET',
        label: 'GET',
    },
    {
        value: 'POST',
        label: 'POST',
    },
    {
        value: 'PUT',
        label: 'PUT',
    }
]

// eslint-disable-next-line import/prefer-default-export
export function IssueTrackerFilter() {
    const { dateFilter, setDateFilter } = useFilter()
    const { issueFilter, setIssueFilters } = useFilter()
    const loadPath = useGetRequest({url: GET_PATHS})
    const paths = loadPath.response ? loadPath.response.paths : []
    const pathOption = [
        { value: '', label: '-' },
        ...paths.map((path) => ({
            value: path,
            label: path,
        })),
    ];

    const [debouncedFilter, setDebouncedFilter] = useState(issueFilter)

    useEffect(() => {
        loadPath.request()
    }, [])

    // Debounce logic
    useEffect(() => {
        const handler = setTimeout(() => {
            setIssueFilters(debouncedFilter)
        }, 500) // 5 seconds debounce

        return () => {
            clearTimeout(handler)
        }
    }, [debouncedFilter])

    const handleInputChange = (field, value) => {
        setDebouncedFilter((prev) => ({
            ...prev,
            [field]: value,
        }))
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? codes.find(
                            (v) => v.value === value,
                        )?.label : 'Choose code')}
                        sx={{ height: 37, width: '10rem' }}
                        value={issueFilter.status_code}
                        onChange={(e) => setIssueFilters({ status_code: e.target.value })}
                    >
                        {codes.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? pathOption.find(
                            (v) => v.value === value,
                        )?.label : 'Choose path')}
                        sx={{ height: 37, width: '10rem' }}
                        value={issueFilter.path}
                        onChange={(e) => setIssueFilters({ path: e.target.value })}
                    >
                        {pathOption.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? methods.find(
                            (v) => v.value === value,
                        )?.label : 'Choose method')}
                        sx={{ height: 37, width: '10rem' }}
                        value={issueFilter.method}
                        onChange={(e) => setIssueFilters({ method: e.target.value })}
                    >
                        {methods.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        ))}
                    </Select>
                    <div className="w-[150px] h-[37px] border overflow-hidden px-[6px] rounded-[5px]">
                        <input
                            type="text"
                            placeholder="username"
                            value={debouncedFilter.username || ''}
                            onChange={(e) => handleInputChange('username', e.target.value)}
                            className="w-[100%] h-[100%] border-none outline-none"
                        />
                    </div>
                    <div className="flex gap-2">
                        {/* Start Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="Start Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={
                                    dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null
                                }
                                onChange={(e) => {
                                    const formattedStartDate = e
                                        ? moment(e)
                                            .format('YYYY-MM-DD')
                                        : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        startPeriod: formattedStartDate,
                                        endPeriod:
                                            prev.endPeriod
                                            && moment(formattedStartDate)
                                                .isBefore(
                                                    moment(prev.endPeriod),
                                                )
                                                ? prev.endPeriod
                                                : formattedStartDate,
                                    }))
                                }}
                                initialFocusedDate={null}
                            />
                        </div>

                        {/* End Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="End Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.endPeriod ? moment(dateFilter.endPeriod) : null}
                                onChange={(e) => {
                                    const formattedEndDate = e
                                        ? moment(e)
                                            .format('YYYY-MM-DD')
                                        : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        endPeriod: formattedEndDate,
                                    }))
                                }}
                                initialFocusedDate={null}
                                minDate={
                                    dateFilter.startPeriod
                                        ? moment(dateFilter.startPeriod)
                                        : null
                                }
                            />
                        </div>
                    </div>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
