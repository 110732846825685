import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import React from 'react'
import { range } from 'lodash'
import { COLORS } from '../../shared/utils/colors'
import { EmptyFolderIcon } from '../svgs/Svgs'
import Pagination from './Pagination'

export default function Table({
    page,
    count,
    items = [],
    columns,
    setPage,
    pageSize,
    className,
    wrapperClass,
    pageCount,
    isStripped,
    renderItem,
    isHoverable = false,
    setPageSize,
    customHeader,
    isScrollable,
    cssClassName,
    headerClassName,
    loading = false,
    loaderItemCount,
}) {
    return (
        <div className={wrapperClass}>
            <div className={css(s.tableWrap)}>
                <table className={cn(`${isScrollable && ''}`, css(s.table, cssClassName), className)}>
                    {customHeader || (
                        <thead className={css(headerClassName, s.table_head)}>
                            <tr>
                                {columns.map((col) => (
                                    <th style={{ width: col.width }} key={col.id}>
                                        {col.name}
                                        {col.icon ? <col.icon /> : null}

                                        {col.count !== undefined
                                            ? (
                                                <span style={{ marginLeft: 5 }}>
                                                    (
                                                    {col.count}
                                                    {' '}
                                                    )
                                                </span>
                                            ) : null}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                    )}

                    <tbody
                        style={{
                            overflowY: 'auto',
                            height: 'calc(100% - 44px)',
                            overflowX: 'hidden',
                        }}
                        className={`${isStripped ? 'striped' : ''} ${isHoverable ? 'striped-hv pointer' : ''}`}
                    >
                        {loaderItemCount && loading ? range(loaderItemCount).map(renderItem) : items.map(renderItem)}
                    </tbody>
                </table>

                {!items.length && !loading && (
                    <div className={cn(css(s.empty), 'w-full flex items-center justify-center')}>
                        <EmptyFolderIcon />
                    </div>
                )}
            </div>

            {pageCount > 1 && (
                <Pagination
                    page={page}
                    count={count}
                    loading={loading}
                    setPage={setPage}
                    pageSize={pageSize}
                    pageCount={pageCount}
                    setPageSize={setPageSize}
                />
            )}
        </div>
    )
}

const s = StyleSheet.create({
    tableWrap: {
        backgroundColor: COLORS.white,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        position: 'relative',
        '::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(201,210,206,0.25)',
        },
    },
    space: {
        marginTop: '2rem',
    },
    table: {
        width: '100%',
        tableLayout: 'fixed',
        filter: 'drop-shadow(0px 2px 4px rgba(130, 130, 130, 0.05))',
        borderCollapse: 'collapse',
        borderRadius: 5,
        background: COLORS.white,
    },
    table_head: {
        position: 'sticky',
        top: -0.5,
        color: COLORS.black,
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'left',
        borderBottom: `1px solid ${COLORS.sepia}`,
        ':nth-child(1n) > :first-child': {
            background: COLORS.white,
            height: 50,
            ':nth-child(1n) > *': {
                padding: '12px 0 ',
            },
            ':nth-child(1n) > th:first-child': {
                paddingLeft: 23,
                flex: 0.5,
            },
        },
    },
    empty: {
        height: '40vh',
    },
})
