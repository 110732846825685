import React from 'react'
import moment from 'moment/moment'
import { TableCell } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Update from '../features/Update'
import Delete from '../features/Delete'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

export default function ClientTableItem({ item, setData }) {
    const { setEntityFilter } = useFilter()
    const navigate = useNavigate()

    const navigateToClient = () => {
        navigate('/masterUser')
        const id = item.id.toString()
        setEntityFilter('master', id)
        console.log(item.i)
    }
    return (
        <>
            <TableCell className="cursor-pointer" onClick={navigateToClient}>
                {item.createdAt ? moment(item.createdAt).format('YYYY-MM-DD HH:MM:SS') : '' }
            </TableCell>

            <TableCell className="cursor-pointer" onClick={navigateToClient}>
                {item.clientName}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={navigateToClient}>
                {item.threshold || '-'}
            </TableCell>

            <TableCell className="cursor-pointer" onClick={navigateToClient}>
                {item.spoofingThreshold || '-'}
            </TableCell>

            <TableCell className="whitespace-nowrap justify-center
             overflow-hidden text-ellipsis  cursor-pointer" onClick={navigateToClient}>
                {item.masterUsersCount}
            </TableCell>

            <TableCell style={{ paddingRight: 0 }} align="right">
                <div className="h-full flex items-center justify-end">
                    <Update item={item} setData={setData} />

                    <Delete id={item.id} setData={setData} />
                </div>
            </TableCell>
        </>
    )
}
