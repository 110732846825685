/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { IDENTITY_LIST,
    CUSTOMER_IDENTITY_LIST, TENANT_ENTITY_FILTER, TENANT_ENTITY_CUSTOMER_FILTER } from '../../../shared/utils/urls'
import IdentityInfoTableItem from './IdentityInfoTableItem'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest } from '../../../shared/hooks/requests'
import Table from '../../../shared/ui/Table'
import IdentityInfoFilter from './IdentityInfoFilter'
import SearchInput from '../../../shared/ui/SearchInput'

const cols = [
    { id: 1, title: 'Photo', padding: '0 0 0 16px' },
    { id: 2, title: 'Cropped' },
    { id: 3, title: 'ID' },
    { id: 4, title: 'First name' },
    { id: 5, title: 'Last name' },
    { id: 6, title: 'Version' },
    { id: 7, title: 'External ID' },
    { id: 8, title: 'PINFL' },
    {id: 9, title: 'Passport Verification' },
    { id: 10, title: 'Loaded camera count' },
    { id: 11, title: 'Unloaded camera count' },
    { id: 12, title: 'Error on camera load' },
    { id: 13, title: 'Updated at' },
    {id: 14, title: 'Details'}
]

export default function IdentityInfoTable() {
    const { identityFilter, setIdentityFilters } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const userType = localStorage.getItem('userType')
    const entityListUrl = userType === 'tenant_admin' ? TENANT_ENTITY_FILTER : TENANT_ENTITY_CUSTOMER_FILTER

    const formatUrl = userType === 'tenant_admin' ? IDENTITY_LIST : CUSTOMER_IDENTITY_LIST
    const getAllEntities = useGetRequest({ url: entityListUrl,
        params: { regionId: identityFilter.rId, districtId: identityFilter.dId } }, [])
    const entity = getAllEntities.response ? getAllEntities.response : []
    const filterEntity = entity.find((item) => item.id === identityFilter.tenantEntityId)

    useEffect(() => {
        if (identityFilter.dId) {
            getAllEntities.request()
        }
    }, [identityFilter.dId])

    const getIdentities = useGetRequest({ url: formatUrl })

    useEffect(() => {
        if (identityFilter.tenantEntityId && !getIdentities.loading) {
            getIdentities.request({
                params: { size: pageSize,
                    page,
                    tenantEntityId: identityFilter.tenantEntityId,
                    search: identityFilter.search,
                    ...(identityFilter.identityGroup !== '' ? { identityGroup: identityFilter.identityGroup } : {}) },
            })
        }
    }, [page, pageSize, identityFilter])

    const data = getIdentities.response ? getIdentities.response.items : []

    const meta = getIdentities.response ? getIdentities.response : {}

    const handleSearch = (value) => {
        setIdentityFilters({ search: value })
    }


    return (
        <div className="w-full flex flex-col gap-6">
            <IdentityInfoFilter />

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Identity Information
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={getIdentities.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <IdentityInfoTableItem
                            item={item}
                            key={item.id}
                            cameraCount={filterEntity?.smartCameras.length}
                            setData={getIdentities.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
