/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'
import moment from 'moment'
import { Update } from '../features/Update'

export function GuestMessageTableItems({ item, setData }) {
    return (
        <>
            <TableCell>
                {item.name}
            </TableCell>
            <TableCell>
                {item.email}
            </TableCell>
            <TableCell>
                {item.phone}
            </TableCell>
            <TableCell>
                {item.description}
            </TableCell>
            <Update id={item.id} status={item.status} setData={setData} />
            <TableCell>
                {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            </TableCell>
        </>
    )
}
