/* eslint-disable */
import React, { useState } from 'react'
import { Modal, TableCell } from '@mui/material'
import { usePutRequest } from '../../../shared/hooks/requests'
import { REPORT_UPDATE } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

const values = [
    {
        id: 'SELECTED',
        value: 'SELECTED',
    },
    {
        id: 'ARCHIVED',
        value: 'ARCHIVED',
    },
]

export function Update({ id, status, setData }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const [open, setOpen] = useState(false)
    const updateStatus = usePutRequest()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const update = async (value) => {
        const { success, response, error } = await updateStatus.request({
            url: `${REPORT_UPDATE}/${id}?_status=${value}`,
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (
                    val.id === response.id ? { ...val, ...response } : val
                )),
            }))
            setOpen(false)
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <>
            <TableCell onClick={handleOpen}>
                <span className={`py-[10px] px-[20px] ${status === 'IN_PROGRESS' ? 'bg-[#FAF0E7] border '
                    + 'border-[#CEAA7B] text-[#CEAA7B]'
                    : status === 'SELECTED' ? 'bg-[#8FF0A9] border border-[#0B9E34] text-[#0B9E34]'
                        : 'bg-[#FF572266] border border-[#FF5722] text-[#FF5722]'
                }
                font-normal rounded-[10px]`}
                >
                    {status}
                </span>
            </TableCell>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <div className="bg-white  flex flex-col items-center gap-[5px] rounded-lg
                shadow-lg w-[250px]  border p-[5px]
                 border-gray-300 dark:border-gray-700"
                >
                    {values.map((item) => (
                        <div
                            className={`w-[100%] h-auto py-[10px] flex items-center justify-center rounded-lg
                         ${item.value === 'SELECTED' ? 'bg-[#8FF0A9] border border-[#0B9E34]'
                            : 'bg-[#FF572266] border border-[#FF5722]'} `}
                            onClick={() => update(item.id)}
                        >
                            <span className={`${item.value === 'SELECTED' ? 'text-[#0B9E34]' : 'text-[#FF5722]'} 
                            font-normal text-[16px]`}
                            >
                                {item.value}
                            </span>
                        </div>
                    ))}
                </div>
            </Modal>
        </>
    )
}
