import React, { useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { GuestMessageFilter } from './GuestMessageFilter'
import Table from '../../../shared/ui/Table'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useLoad } from '../../../shared/hooks/requests'
import { GET_MESSAGES } from '../../../shared/utils/urls'
import { GuestMessageTableItems } from './GuestMessageTableItems'

const cols = [
    {
        id: 1,
        title: 'Name',
    },
    {
        id: 2,
        title: 'Email',
    },
    {
        id: 3,
        title: 'Phone',
    },
    {
        id: 4,
        title: 'Description',
    },
    {
        id: 5,
        title: 'Status',
    },
    {
        id: 6,
        title: 'CreatedAt',
        align: 'left',
    },
]

// eslint-disable-next-line import/prefer-default-export
export function GuestMessageTable() {
    const { guestFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(5)
    const loadMessages = useLoad(
        { url: GET_MESSAGES,
            params: { page,
                size: pageSize,
                ...(guestFilter.status !== '' ? { _status: guestFilter.status } : {}) } },
        [page, pageSize, guestFilter],
    )
    const data = loadMessages.response ? loadMessages.response.items : []
    const meta = loadMessages.response ? loadMessages.response.total : {}
    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <GuestMessageFilter />
            </div>
            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Guest Messages
                    </Typography>
                </div>
                <Table
                    page={page - 1}
                    totalCount={meta}
                    loading={loadMessages.loading}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <GuestMessageTableItems
                            item={item}
                            key={item.id}
                            setData={loadMessages.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
