import React, { useEffect, useRef } from 'react'
import { Form, Formik } from 'formik'
import { number, object, string } from 'yup'
import PhoneInput from '../../../shared/ui/PhoneInput'
import { JETSON_DEVICES, FILE_UPLOAD, USER_ROLES } from '../../../shared/utils/urls'
import { useLoad, useGetRequest } from '../../../shared/hooks/requests'
import { isImageReal } from '../../../shared/utils/string'
import useImageUpload from '../../../shared/hooks/imageUploadHandler'
import { PhotoCameraIcon } from '../../../components/svgs/Svgs'
import FormTextField from '../../../shared/ui/FormTextField'
import SelectInput from '../../../shared/ui/SelectInput'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    firstName: '',
    lastName: '',
    pinfl: '',
    email: '',
    phone: '',
    photo: '',
    roleId: '',
    jetsonDeviceId: '',
    fileName: '',
    description: '',
}

export default function EntityUserForm({
    isUser = true,
    onSubmit = () => {},
    onClose = () => {},
    loading = false,
    initialValues,
    isEdit,
}) {
    const loadJetsons = useLoad({ url: JETSON_DEVICES, params: { is_active: true, page: 1, size: 100 } }, [])
    const jetsons = loadJetsons.response && loadJetsons.response.items ? loadJetsons.response.items : []
    const jetsonOptions = jetsons.map((b) => ({ value: b.id, label: b.deviceName || '-' }))

    const formikRef = useRef()
    const inputRef = useRef(null)
    const loadRoles = useGetRequest({ url: USER_ROLES })
    const { onFileUpload } = useImageUpload()
    const roleOptions = loadRoles.response
        ? loadRoles.response.map((role) => ({ value: role.id, label: role.name })) : []

    useEffect(() => {
        if (isUser) loadRoles.request()
    }, [])

    const VALIDATION_SCHEMA = object().shape({
        firstName: string().required('First name is required'),
        lastName: string().required('Last name is required'),
        // pinfl: number().required('PINFL is required'),
        email: string().test(
            'is-email',
            'Given email address is not valid',
            (value) => (`${value}`).match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/),
        ).required('Email is required'),
        phone: string().required('Phone is required'),
        photo: string().optional(),
        roleId: isUser ? number().required('Role is required') : number().optional(),
        jetsonDeviceId: !isUser ? number().required('Jetson Device is required') : number().optional(),
        fileName: string().optional(),
        description: string().optional(),
    })

    return (
        <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, handleChange }) => (
                <Form className="h-[60%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">
                        <div className="w-full flex items-center gap-3 justify-between">
                            {isImageReal(values.photo) ? (
                                <img src={values.photo} alt="#Admin" className=" w-[60px] h-[60px] rounded" />
                            ) : (
                                <div className="flex items-center justify-center bg-gray-100 w-[60px] h-[60px] rounded">
                                    <PhotoCameraIcon />
                                </div>
                            )}

                            <FormTextField
                                fullWidth
                                type="text"
                                label="Photo"
                                name="fileName"
                                onClick={() => inputRef.current.click()}
                            />

                            <input
                                id="file"
                                type="file"
                                name="photo"
                                ref={inputRef}
                                readOnly="readonly"
                                className="not-visible"
                                accept="image/png, image/jpg, image/jpeg"
                                onChange={(e) => onFileUpload(e, setFieldValue, 'photo', FILE_UPLOAD)}
                            />
                        </div>

                        <FormTextField
                            label="First name"
                            name="firstName"
                            required
                            handleChange={handleChange}
                        />

                        <FormTextField
                            required
                            label="Last name"
                            name="lastName"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            required
                            label="PINFL"
                            name="pinfl"
                            handleChange={handleChange}
                        />

                        <FormTextField
                            label="Email"
                            name="email"
                            required
                            handleChange={handleChange}
                        />

                        <PhoneInput
                            required
                            label="Phone number"
                            name="phone"
                            handleChange={handleChange}
                        />

                        {isUser ? (
                            <SelectInput
                                required
                                loading={loadRoles.loading}
                                label="Select role"
                                name="roleId"
                                onChange={(e) => setFieldValue('roleId', e.target.value)}
                                menuItems={roleOptions}
                            />
                        ) : null}

                        {!isUser ? (
                            <SelectInput
                                required
                                loading={loadJetsons.loading}
                                label="Select jetson device"
                                name="jetsonDeviceId"
                                onChange={(e) => setFieldValue('jetsonDeviceId', e.target.value)}
                                menuItems={jetsonOptions}
                            />
                        ) : null}
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button"
                        />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit"
                        />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
