class WebSocketService {
    static instances = {}

    constructor(url, params = {}, maxReconnectAttempts = 5) {
        this.url = this.constructUrl(url, params)
        this.listeners = new Set()
        this.maxReconnectAttempts = maxReconnectAttempts
        this.reconnectAttempts = 0
        this.manualClose = false

        if (WebSocketService.instances[this.url]) {
            // eslint-disable-next-line no-constructor-return
            return WebSocketService.instances[this.url] // Возвращаем существующий экземпляр
        }

        this.initializeWebSocket()
        WebSocketService.instances[this.url] = this // Сохраняем экземпляр
    }

    // eslint-disable-next-line class-methods-use-this
    constructUrl(url, params) {
        const query = Object.entries(params)
            // eslint-disable-next-line no-unused-vars
            .filter(([_, value]) => value != null && value !== '') // Убираем пустые параметры
            .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join('&')
        return query ? `${url}?${query}` : url
    }

    initializeWebSocket() {
        this.ws = new WebSocket(this.url)

        this.ws.onmessage = (event) => {
            const data = JSON.parse(event.data)
            this.listeners.forEach((listener) => listener(data))
        }

        this.ws.onclose = () => {
            if (!this.manualClose) {
                this.attemptReconnect()
            }
        }

        this.ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }
    }

    attemptReconnect() {
        if (this.reconnectAttempts < this.maxReconnectAttempts) {
            this.reconnectAttempts++
            setTimeout(() => {
                this.initializeWebSocket()
            }, 1000 * this.reconnectAttempts)
        } else {
            console.error('Max reconnect attempts reached')
        }
    }

    addListener(listener) {
        this.listeners.add(listener)
    }

    removeListener(listener) {
        this.listeners.delete(listener)
        if (this.listeners.size === 0) {
            this.close()
        }
    }

    close() {
        this.manualClose = true
        if (this.ws) {
            this.ws.close()
        }
        delete WebSocketService.instances[this.url] // Удаляем экземпляр
    }
}

export default WebSocketService
