import { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import WebSocketService from '../utils/socket'

export default function useWebSocket(url, params = {}) {
    const [allMessages, setAllMessages] = useState([])
    const [filteredData, setFilteredData] = useState([])

    const processMessages = useCallback(
        (messages) => messages.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))),
        [],
    )

    const handleMessage = useCallback((newMessage) => {
        setAllMessages((prevMessages) => [newMessage, ...prevMessages])
    }, [])

    useEffect(() => {
        const wsService = new WebSocketService(url, params)
        wsService.addListener(handleMessage)

        return () => {
            wsService.removeListener(handleMessage)
        }
    }, [url, JSON.stringify(params), handleMessage]) // Используем JSON.stringify для отслеживания изменений params

    useEffect(() => {
        setFilteredData(processMessages(allMessages))
    }, [allMessages, processMessages])

    return { filteredData }
}
