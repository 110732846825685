/* eslint-disable */
import React, { useState } from 'react'
import { Box, Modal, TableCell } from '@mui/material'
import moment from 'moment/moment'
import noPhoto from '../../../static/images/no-photo.png'
import { DetailsModal } from './DetailsModal'
import { Update } from '../features/Update'

export function TenantAdminReportsTableItems({ item, setData }) {
    const [open, setOpen] = useState(false)
    const [modalImage, setModalImage] = useState(null)

    const photo = item.attendance ? item.attendance.snapshotUrl : ''
    const firstName = item.attendance.identity ? item.attendance.identity.firstName : ''
    const lastName = item.attendance.identity ? item.attendance.identity.lastName : ''
    const attendTime = item.attendance ? item.attendance.attendanceDatetime : ''

    const handleOpen = (image) => {
        setModalImage(image)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setModalImage(null)
    }

    return (
        <>
            <TableCell>{item.attendanceId}</TableCell>
            <TableCell>
                <div className="w-[50px] rounded-[10px] overflow-hidden h-[50px]">
                    <img
                        src={photo || noPhoto}
                        alt="Main"
                        className="w-[100%] h-[100%] object-cover"
                        onClick={() => handleOpen(photo)}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
            </TableCell>
            <TableCell>
                {firstName}
                {' '}
                {lastName}
            </TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</TableCell>
            <TableCell>{moment(attendTime).format('DD.MM.YYYY HH:mm')}</TableCell>
            <Update id={item.id} status={item.status} setData={setData} />
            <TableCell>
                <DetailsModal id={item.attendanceId} item={item} firstName={firstName} lastName={lastName}
                              photo={photo} />
            </TableCell>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="enlarged-image"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    sx={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '200px',
                        maxHeight: '200px',
                        outline: 'none',
                    }}
                >
                    {modalImage && (
                        <img
                            src={modalImage}
                            alt="Enlarged view"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    )}
                </Box>
            </Modal>
        </>
    )
}
