/* eslint-disable */
import React, { memo, useContext, useMemo } from 'react'
import MuiDrawer from '@mui/material/Drawer'
import { IconButton, List, styled, Divider } from '@mui/material'
import { ChevronLeft } from '@mui/icons-material'
import { COLORS } from '../../shared/utils/colors'
import { Context } from '../contexts/GlobalContext'
import { CameraIcon,
    BranchAnalysis,
    DashboardIcon,
    WorkspaceIcon,
    FirmwareIcon, InfrastructureIcon, IntegrationApiIcon, Logo,
    ModuleIcon,
    OfficesIconSizeL,
    PersonIcon,
    SmartCameraIcon,
    TenantsIcon,
    UsersIcon,
    JetsonDeviceIcon,
    JetsonDeviceProfileIcon,
    FaceAuthIcon,
    ClientsIcon,
    MUserIcon,
    ALIcon,
    DeepstreamIcon,
    LogsIcon,
    AnalyticsIcon,
    QueueAnalyticsIcon,
    DevicePreviewIcon,
    LockIcon,
    SpeechIcon,
    DeploymentIcon,
    PhotocameraIcon,
    UpgradeIcon,
    StreamIcon,
    SmartCameraProfile } from '../../components/svgs/Svgs'
import useTenantProfileStore from '../../shared/store/tenantProfileStore'
import SidebarItem from './SidebarItem'

const drawerWidth = 250

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

function MemoizedComponent({ open, close }) {
    const userType = localStorage.getItem('userType')
    const faceAuthToken = JSON.parse(localStorage.getItem('faceAuthToken'))
    const { setOpenFaceAuthModal } = useContext(Context)
    const { entityFilters } = useTenantProfileStore()
    const isBranchAnalyticsDisabled = entityFilters.parent.hierarchyLevel === 3

    const links = useMemo(() => ({
        system_admin: [
            { id: 1, path: '/tenants', title: 'Tenants', Icon: UsersIcon },
            { id: 3, path: '/tenant-profiles', title: 'Tenant profiles', Icon: TenantsIcon },
            { id: 4, path: '/modules', title: 'Service modules', Icon: ModuleIcon },
            {id: 5, path: '/messages', title: 'Guest Messages', Icon: InfrastructureIcon},
            {
                id: 5,
                path: null,
                onClick: () => (!faceAuthToken ? setOpenFaceAuthModal(true) : {}),
                title: 'Face Authentication',
                Icon: FaceAuthIcon,
                links: [
                    { id: 1, path: '/client', title: 'Client', disabled: !faceAuthToken, Icon: ClientsIcon },
                    { id: 2, path: '/masterUser', title: 'Master User', disabled: !faceAuthToken, Icon: MUserIcon },
                    { id: 3, path: '/users', title: 'Users', disabled: !faceAuthToken, Icon: UsersIcon },
                    {
                        id: 4,
                        path: '/authentication-logs',
                        title: 'Authentication Logs',
                        disabled: !faceAuthToken,
                        Icon: ALIcon,
                    },
                    { id: 5, path: '/review-photos', title: 'Review Photos', disabled: !faceAuthToken, Icon: ALIcon },

                ],
            },
        ],
        tenant_admin: [
            { id: 1, path: '/company-structure', title: 'Company structure', Icon: OfficesIconSizeL },
            { id: 2, path: '/branches', title: 'Branch management', Icon: PersonIcon },
            { id: 3, path: '/integration', title: 'Integration API', Icon: IntegrationApiIcon },
            {
                id: 4,
                path: null,
                title: 'Infrastructure',
                Icon: InfrastructureIcon,
                links: [
                    { id: 1, path: '/infrastructure', title: 'Infrastructure', Icon: InfrastructureIcon },
                    { id: 2, path: '/infrastructure/smartcamera-manage', title: 'Smart Camera', Icon: SmartCameraIcon },
                    {
                        id: 3,
                        path: '/infrastructure/smartcamera-profile',
                        title: 'Smart Camera Profile',
                        Icon: SmartCameraProfile,
                    },
                    { id: 4, path: '/infrastructure/camera-manage', title: 'Camera', Icon: CameraIcon },
                    { id: 5, path: '/infrastructure/firmware', title: 'Firmware', Icon: FirmwareIcon },
                    { id: 6, path: '/jetson', title: 'Jetson Device', Icon: JetsonDeviceIcon },
                    { id: 7, path: '/jetson-profile', title: 'Jetson Device Profile', Icon: JetsonDeviceProfileIcon },
                ],
            },
            {
                id: 4,
                path: '',
                title: 'Deployment',
                Icon: DeploymentIcon,
                links: [
                    {
                        id: 1,
                        path: '/deployment/deepstream-applications',
                        title: 'Deepstream Applications',
                        Icon: DeepstreamIcon,
                    },
                    {
                        id: 2,
                        path: '/deployment/logs',
                        title: 'Logs',
                        Icon: LogsIcon,
                    },
                    {
                        id: 3,
                        path: '/deployment/camera-management',
                        title: 'Camera Management',
                        Icon: PhotocameraIcon,
                    },
                    {
                        id: 4,
                        path: '/deployment/upgrade',
                        title: 'Upgrade',
                        Icon: UpgradeIcon,
                    },
                    {
                        id: 5,
                        path: '/deployment/secure-live-stream',
                        title: 'Secure Live Stream',
                        Icon: StreamIcon,
                    },
                    {
                        id: 6,
                        path: '/deployment/port-forwarding',
                        title: 'Port Forwarding',
                        Icon: PhotocameraIcon,
                    },
                ],
            },
            { id: 5,
                path: '/identity-attendance-analytics',
                title: 'Identity Attendance Analytics',
                Icon: InfrastructureIcon },
            { id: 6, path: '/attendace-analytics', title: 'Attendance Analytics', Icon: InfrastructureIcon },
            { id: 7, path: '/identity-info', title: 'Identity Info', Icon: InfrastructureIcon },
            { id: 8, path: '/tenant-entity-management', title: 'Tenant Entity Management', Icon: InfrastructureIcon },
            {
                id: 9,
                path: '/real-time-face-recognition',
                title: 'Real Time Face Recognition',
                Icon: InfrastructureIcon,
            },
            { id: 10, path: '/reports', title: 'reports', Icon: InfrastructureIcon },
            {id: 11, path: '/system-integrations', title: 'System Integration', Icon: InfrastructureIcon },
            {id: 12, path: '/issue-tracker', title: 'Issue Tracker', Icon: InfrastructureIcon },
            {id: 13, path: '/similarity', title: 'Similarity', Icon: InfrastructureIcon },

        ],
        customer_user: [
            {
                id: 1,
                path: '',
                title: 'Visitor Analytics',
                Icon: DashboardIcon,
                links: !isBranchAnalyticsDisabled ? [
                    { id: 1, path: '/analytics', title: 'Aanalytics', Icon: AnalyticsIcon },
                    { id: 2, path: 'analytics/branches', title: 'Branch analytics', Icon: BranchAnalysis },
                ] : undefined,
            },
            {
                id: 2,
                path: '',
                title: 'Workspace Analytics',
                Icon: WorkspaceIcon,
                links: [
                    { id: 1, path: 'workspace-analytics/analytics', title: 'Analytics', Icon: AnalyticsIcon },
                    { id: 2, path: 'workspace-analytics/queue', title: 'Queue analytics', Icon: QueueAnalyticsIcon },
                    {
                        id: 3,
                        path: 'workspace-analytics/device-preview',
                        title: 'Device preview',
                        Icon: DevicePreviewIcon,
                    },
                ],

            },
            { id: 3, path: '/infrastructure/smartcamera', title: 'Smart Camera', Icon: SmartCameraIcon },
            { id: 4, path: '/safezone-alarm', title: 'Safe zone alarm', Icon: LockIcon },
            {
                id: 5,
                path: '',
                title: 'Speech Analytics',
                Icon: SpeechIcon,
                links: [
                    { id: 1, path: '/speech-analytics', title: 'Analytics', Icon: AnalyticsIcon },
                ],

            },
            // { id: 4, path: '/left-Items', title: 'Left-Items', Icon: InfrastructureIcon },
            { id: 6, path: '/stream-analytics', title: 'Stream Analytics', Icon: InfrastructureIcon },
            { id: 7,
                path: '/identity-attendance-analytics',
                title:
                    'Identity Attendance Analytics',
                Icon: InfrastructureIcon },
            { id: 8, path: '/identity-info', title: 'Identity Info', Icon: InfrastructureIcon },
            { id: 9, path: '/tenant-entity-management', title: 'Tenant Entity Management', Icon: InfrastructureIcon },
            {
                id: 10,
                path: '/real-time-face-recognition',
                title: 'Real Time Face Recognition',
                Icon: InfrastructureIcon,
            },
        ],
    }), [isBranchAnalyticsDisabled])

    return (
        <Drawer
            sx={{
                '& .MuiDrawer-paper': {
                    backgroundColor: COLORS.mainBlueBg,
                },
            }}
            variant="permanent"
            open={open}
        >
            <DrawerHeader>
                <Logo />

                <IconButton onClick={close}>
                    <ChevronLeft htmlColor={COLORS.white} />
                </IconButton>
            </DrawerHeader>

            <Divider />

            <List>
                {links[userType] && links[userType].map((link) => (
                    <SidebarItem key={link.title} link={link} isSidebarOpen={open} />
                ))}
            </List>
        </Drawer>
    )
}

export const Sidebar = memo(MemoizedComponent)
