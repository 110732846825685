import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import { IssueTrackerFilter } from './IssueTrackerFilter'
import SearchInput from '../../../shared/ui/SearchInput'
import Table from '../../../shared/ui/Table'
import { IssueTrackerTableItems } from './IssueTrackerTableItems'
import { useLoad } from '../../../shared/hooks/requests'
import { GET_ISSUES } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'

const cols = [
    {
        id: 1,
        title: 'username',
        width: '12.5%',
        align: 'center',
    },
    {
        id: 2,
        title: 'status',
        width: '12.5%',
        align: 'center',
    },
    {
        id: 3,
        title: 'method',
        width: '12.5%',
        align: 'center',
    },
    {
        id: 4,
        title: 'time',
        align: 'center',
        width: '12.5%',
    },
    {
        id: 6,
        title: 'taken_time',
        width: '12.5%',
        align: 'center',
    },
    {
        id: 7,
        title: 'request_path',
        width: '12.5%',
        align: 'center',
    },
    {
        id: 8,
        title: 'Batafsil',
        align: 'center',
        width: '12.5%',
    },
]

// eslint-disable-next-line import/prefer-default-export
export function IssueTrackerTable() {
    const { dateFilter } = useFilter()
    const { issueFilter, setIssueFilters } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadIssues = useLoad({ url: GET_ISSUES,
        params: {
            limit: pageSize,
            offset: page,
            ...(issueFilter.search !== '' ? { search: issueFilter.search } : {}),
            ...(issueFilter.status_code !== '' ? { response_status_code: issueFilter.status_code } : {}),
            ...(issueFilter.path !== '' ? { request_path: issueFilter.path } : {}),
            ...(issueFilter.method !== '' ? { request_method: issueFilter.method } : {}),
            ...(issueFilter.username !== '' ? { username: issueFilter.username } : {}),
            request_time_start: dateFilter.startPeriod,
            request_time_end: dateFilter.endPeriod,
        } }, [pageSize, page, issueFilter, dateFilter])
    const data = loadIssues.response ? loadIssues.response.items : []
    const meta = loadIssues.response ? loadIssues.response.total : []

    const handleSearch = (value) => {
        setIssueFilters({ search: value })
    }

    useEffect(() => {
        setPage(1)
    }, [issueFilter, dateFilter])

    return (
        <div className="flex flex-col w-[100%] gap-[20px]">
            <div className="w-[100%]">
                <IssueTrackerFilter />
            </div>
            <Paper square className="flex flex-col w-[100%] gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Issue Tracker
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    loading={loadIssues.loading}
                    totalCount={meta}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <IssueTrackerTableItems
                            item={item}
                            key={item.id}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
