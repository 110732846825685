/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@mui/material'
import { useGetRequest } from '../../../shared/hooks/requests'
import { REPORTS_DETAILS } from '../../../shared/utils/urls'
import moment from 'moment'

export function DetailsModal({ id, item, firstName, lastName, photo }) {
    const pinfl = item.attendance.identity ? item.attendance.identity.pinfl : ''
    const [open, setOpen] = useState(false)
    const loadDetails = useGetRequest({ url: `${REPORTS_DETAILS}${id}` })
    const data = loadDetails.response ? loadDetails.response : null

    const handleOpen = () => {
        setOpen(true)
        loadDetails.request()
    }
    const handleClose = () => {
        setOpen(false)
    }

    const mainInArea = data ? data.similarityMainPhotoInArea : []
    const inArea = data ? data.similarityInArea : []
    const inEntity = data ? data.similarityInEntity : []
    const mainInEntity = data ? data.similarityMainPhotoInEntity : []
    const spoofing = data ? data.spoofing : []
    const pack = data ? data.package : []
    
    return (
        <>
            <button
                onClick={handleOpen}
                className="py-[10px] px-[15px] bg-main-300 rounded-[10px]
            text-white font-normal"
            >
                Details
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <div className="bg-white dark:bg-gray-800 rounded-lg
                shadow-lg py-5 px-4 w-[800px]  border flex flex-col items-start gap-[10px]
                 border-gray-300 dark:border-gray-700"
                >
                    <div className="flex flex-col w-full gap-[10px] overflowY-auto h-[600px]">
                        <div className="w-full flex items-center justify-between">
                            <div className="w-[50%] flex  flex-col items-start gap-[10px]">
                                <div className="flex items-center gap-[20px]">
                                    <span className="text-[16px] font-[500] text-black">AttendanceId:</span>
                                    <span>{item.attendanceId}</span>
                                </div>
                                <div className="flex items-center gap-[20px]">
                                    <span className="text-[16px] font-[500] text-black">IndentityName:</span>
                                    <span>{firstName} {lastName}</span>
                                </div>
                                <div className="flex items-center gap-[20px]">
                                    <span className="text-[16px] font-[500] text-black">PINFL:</span>
                                    <span>{pinfl}</span>
                                </div>
                            </div>
                            <div className="w-[50%]  flex flex-col items-center justify-center">
                                <div className="h-[250px] w-[250px] overflow-hidden">
                                    <img src={photo} alt="" className="w-[100%] h-[132%] object-cover"/>
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-[10px] flex flex-col items-center">
                            <span className="text-[16px] font-[600] text-black">SimilarityInArea</span>
                            {inArea?.map((item) => (
                                <div className="w-full flex border-b pb-[10px] pt-[10px] items-center gap-[20px]">
                                    <div className="w-[50%] h-[100%] flex items-center gap-[10px]">
                                        <div className="w-[50%] h-[100%]">
                                            {item.imageUrl ? (
                                                <img src={item.imageUrl} alt="imageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                        <div className="w-[50%] h-[100%]">
                                            {item.similarImageUrl ? (
                                                <img src={item.similarImageUrl} alt="similarImageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[50%] flex flex-col items-start gap-[10px]">
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">AttendanceId:</span>
                                            <span>{item.attendanceId ? item.attendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">
                                                captureTimestamp:</span>
                                            <span>{item.captureTimestamp ? item.captureTimestamp : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">CreatedAt:</span>
                                            <span>{spoofing.createdAt ? moment(item.createdAt)
                                                .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">Distance:</span>
                                            <span>{item.distance ? item.distance : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">IdentityId:</span>
                                            <span>{item.identityId ? item.identityId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarAttendanceId:</span>
                                            <span>{item.similarAttendanceId ? item.similarAttendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarCaptureTimestamp:</span>
                                            <span>{item.similarCaptureTimestamp ? item.similarCaptureTimestamp
                                                : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full pt-[10px] flex flex-col items-center">
                            <span className="text-[16px] font-[600] text-black">SimilarityMainPhotoInArea</span>
                            {mainInArea?.map((item) => (
                                <div className="w-full flex border-b pb-[10px] pt-[10px] items-center gap-[20px]">
                                    <div className="w-[50%] h-[100%] flex items-center gap-[10px]">
                                        <div className="w-[50%] h-[100%]">
                                            {item.imageUrl ? (
                                                <img src={item.imageUrl} alt="imageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                        <div className="w-[50%] h-[100%]">
                                            {item.similarImageUrl ? (
                                                <img src={item.similarImageUrl} alt="similarImageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[50%] flex flex-col items-start gap-[10px]">
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">AttendanceId:</span>
                                            <span>{item.attendanceId ? item.attendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">
                                                captureTimestamp:</span>
                                            <span>{item.captureTimestamp ? item.captureTimestamp : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">CreatedAt:</span>
                                            <span>{spoofing?.createdAt ? moment(item.createdAt)
                                                .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">Distance:</span>
                                            <span>{item.distance ? item.distance : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">IdentityId:</span>
                                            <span>{item.identityId ? item.identityId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarAttendanceId:</span>
                                            <span>{item.similarAttendanceId ? item.similarAttendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarCaptureTimestamp:</span>
                                            <span>{item.similarCaptureTimestamp ? item.similarCaptureTimestamp
                                                : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full pt-[10px] flex flex-col items-center">
                            <span className="text-[16px] font-[600] text-black">SimilarityInEntity</span>
                            {inEntity?.map((item) => (
                                <div className="w-full flex border-b pb-[10px] pt-[10px] items-center gap-[20px]">
                                    <div className="w-[50%] h-[100%] flex items-center gap-[10px]">
                                        <div className="w-[50%] h-[100%]">
                                            {item.imageUrl ? (
                                                <img src={item.imageUrl} alt="imageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                        <div className="w-[50%] h-[100%]">
                                            {item.similarImageUrl ? (
                                                <img src={item.similarImageUrl} alt="similarImageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[50%] flex flex-col items-start gap-[10px]">
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">AttendanceId:</span>
                                            <span>{item.attendanceId ? item.attendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">
                                                captureTimestamp:</span>
                                            <span>{item.captureTimestamp ? item.captureTimestamp : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">CreatedAt:</span>
                                            <span>{spoofing.createdAt ? moment(item.createdAt)
                                                .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">Distance:</span>
                                            <span>{item.distance ? item.distance : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">IdentityId:</span>
                                            <span>{item.identityId ? item.identityId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarAttendanceId:</span>
                                            <span>{item.similarAttendanceId ? item.similarAttendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarCaptureTimestamp:</span>
                                            <span>{item.similarCaptureTimestamp ? item.similarCaptureTimestamp
                                                : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full pt-[10px] flex flex-col items-center">
                            <span className="text-[16px] font-[600] text-black">SimilarityMainPhotoInEntity</span>
                            {mainInEntity?.map((item) => (
                                <div className="w-full flex border-b pb-[10px] pt-[10px] items-center gap-[20px]">
                                    <div className="w-[50%] h-[100%] flex items-center gap-[10px]">
                                        <div className="w-[50%] h-[100%]">
                                            {item.imageUrl ? (
                                                <img src={item.imageUrl} alt="imageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                        <div className="w-[50%] h-[100%]">
                                            {item.similarImageUrl ? (
                                                <img src={item.similarImageUrl} alt="similarImageUrl"
                                                     className="w-[100%] h-[100%] object-cover"/>
                                            ) : (
                                                <div className="w-[100%] h-[100%] border"></div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-[50%] flex flex-col items-start gap-[10px]">
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">AttendanceId:</span>
                                            <span>{item.attendanceId ? item.attendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">
                                                captureTimestamp:</span>
                                            <span>{item.captureTimestamp ? item.captureTimestamp : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">CreatedAt:</span>
                                            <span>{spoofing.createdAt ? moment(item.createdAt)
                                                .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">Distance:</span>
                                            <span>{item.distance ? item.distance : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span className="text-[16px] font-[500] text-black">IdentityId:</span>
                                            <span>{item.identityId ? item.identityId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarAttendanceId:</span>
                                            <span>{item.similarAttendanceId ? item.similarAttendanceId : '-'}</span>
                                        </div>
                                        <div className="flex items-center gap-[20px]">
                                            <span
                                                className="text-[16px] font-[500] text-black">
                                                similarCaptureTimestamp:</span>
                                            <span>{item.similarCaptureTimestamp ? item.similarCaptureTimestamp
                                                : '-'}</span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="w-full pt-[10px] flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] font-[600] text-black">Spoofing</span>
                            <div className="w-full mx-auto grid grid-cols-4 gap-[20px]">
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">AttendanceId</span>
                                    <span>{spoofing?.attendanceId ? spoofing.attendanceId : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">CreatedAt</span>
                                    <span>{spoofing?.createdAt ? moment(spoofing.createdAt)
                                        .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">FakeScore</span>
                                    <span>{spoofing?.fakeScore ? spoofing.fakeScore : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">is_spoofed</span>
                                    <span>{spoofing?.isSpoofed ? (spoofing.isSpoofed === false ? 'False'
                                        : 'True') : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">RealScore</span>
                                    <span>{spoofing?.realScore ? spoofing.realScore : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">Score</span>
                                    <span>{spoofing?.score ? spoofing.score : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">UpdatedAt</span>
                                    <span>{spoofing?.updatedAt ? moment(spoofing.updatedAt)
                                        .format('DD.MM.YYYY HH:mm') : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">ID</span>
                                    <span>{spoofing?.id ? spoofing.id : '-'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="w-full pt-[10px] flex flex-col gap-[10px] items-center">
                            <span className="text-[16px] font-[600] text-black">Package</span>
                            <div className="w-full mx-auto grid grid-cols-4 gap-[20px]">
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">appLicensingVerdict</span>
                                    <span>{pack?.appLicensingVerdict ? pack.appLicensingVerdict : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">appRecognitionVerdict</span>
                                    <span>{pack?.appRecognitionVerdict ? pack.appRecognitionVerdict : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">deviceActivityLevel</span>
                                    <span>{pack?.deviceActivityLevel ? pack.deviceActivityLevel : '-'}</span>
                                </div>
                                <div className="flex flex-col items-center gap-[10px]">
                                    <span className="text-[16px] font-[500] text-black">playProtectVerdict</span>
                                    <span>{pack?.playProtectVerdict ? pack.playProtectVerdict : '-'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button onClick={handleClose}
                            className="py-[10px] px-[20px] bg-main-300 rounded-[15px] text-white font-400">Close
                    </button>
                </div>
            </Modal>
        </>
    )
}
