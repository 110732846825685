/* eslint-disable */
import React from 'react'
import { TableCell } from '@mui/material'
import moment from 'moment'
import { DetailsModal } from './Details'

// eslint-disable-next-line import/prefer-default-export
export function IssueTrackerTableItems({ item }) {
    return (
        <>
            <TableCell classes="w-[100%]">
                <div className="w-[100%] flex items-center justify-center">
                    <span>{item.username}</span>
                </div>
            </TableCell>
            <TableCell classes="w-[100%]">
                <div className="w-[100%] flex items-center justify-center">
                    <span>{item.responseStatusCode}</span>
                </div>
            </TableCell>
            <TableCell classes="w-[100%]">
                <div className="w-[100%] flex items-center justify-center">
                    <span>{item.requestMethod}</span>
                </div>
            </TableCell>
            <TableCell>
                {moment(item.requestTime).format('YYYY-MM-DD HH:mm:ss')}
            </TableCell>
            <TableCell classes="w-[100%]">
                <div className="w-[100%] flex items-center justify-center">
                    <span>{item.takenTime.toFixed(2)}</span>
                </div>
            </TableCell>
            <TableCell classes="w-[100%]">
                <div className="w-[100%] flex items-center justify-center">
                    <span>{item.requestPath}</span>
                </div>
            </TableCell>
            <TableCell classes="w-[100%]">
                <DetailsModal data={item} />
            </TableCell>
        </>
    )
}
