import React, { useEffect, useCallback, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MenuItem, Paper, Select } from '@mui/material'
import Skeleton from 'react-loading-skeleton'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { debounce } from 'lodash'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ALLOWED_REGIONS, DISTRICTS_LIST } from '../../../shared/utils/urls'

export function SystemIntegrationFilter() {
    const { systemFilter, setSystemFilters } = useFilter()
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: systemFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOptions = districts.length
        ? districts.map((district) => ({ value: district.id, label: district.name }))
        : []

    const [mttIdInput, setMttIdInput] = useState(systemFilter.mttId || '')

    useEffect(() => {
        setSystemFilters({ district: '' })
        if (systemFilter.region) {
            getDistricts.request()
        }
    }, [systemFilter.region])

    const debouncedSetFilter = useCallback(
        debounce((value) => {
            setSystemFilters({ mttId: value })
        }, 500),
        [setSystemFilters],
    )

    const handleInputChange = (field, value) => {
        if (field === 'mttId') {
            setMttIdInput(value) // обновляем локальное состояние
            debouncedSetFilter(value) // вызываем debounce
        } else {
            setSystemFilters({ [field]: value })
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? regionOptions.find((v) => v.value === value)?.label : 'Choose region')}
                        sx={{ height: 37, width: '10rem' }}
                        value={systemFilter.region}
                        onChange={(e) => setSystemFilters({ region: e.target.value })}
                    >
                        {!loadRegions.loading && regionOptions.length ? (
                            regionOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))
                        ) : loadRegions.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : (
                            <MenuItem value="">Nothing Found</MenuItem>
                        )}
                    </Select>

                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? districtOptions.find((v) => v.value === value)?.label : 'Choose district')}
                        sx={{ height: 37, width: '10rem' }}
                        value={systemFilter.district}
                        disabled={!districtOptions.length}
                        onChange={(e) => setSystemFilters({ district: e.target.value })}
                    >
                        {!getDistricts.loading && districtOptions.length ? (
                            districtOptions.map((menuItem) => (
                                <MenuItem key={menuItem.value} value={menuItem.value}>
                                    {menuItem?.label}
                                </MenuItem>
                            ))
                        ) : getDistricts.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : (
                            <MenuItem value="">Nothing Found</MenuItem>
                        )}
                    </Select>

                    <div className="w-[150px]">
                        <DatePicker
                            label="Date"
                            sx={{
                                height: 37,
                                width: '150px',
                                '& .MuiInputBase-root': { height: 37 },
                                '& .MuiFormLabel-root': { top: '-8px' },
                            }}
                            inputFormat="DD-MM-YYYY"
                            value={systemFilter.date ? moment(systemFilter.date) : null}
                            onChange={(e) => {
                                const formattedEndDate = e ? moment(e).format('YYYY-MM-DD') : null
                                setSystemFilters({ date: formattedEndDate })
                            }}
                            initialFocusedDate={null}
                        />
                    </div>
                    <div className="w-[150px] h-[37px] border overflow-hidden px-[6px] rounded-[5px]">
                        <input
                            type="text"
                            placeholder="MTT ID"
                            value={mttIdInput}
                            onChange={(e) => handleInputChange('mttId', e.target.value)}
                            className="w-[100%] h-[100%] border-none outline-none"
                        />
                    </div>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
