import React from 'react'
import { Drawer } from '@mui/material'
import { useMessage } from '../../../../shared/hooks/message'
import useHandleErrors from '../../../../shared/hooks/handleErrorMessage'
import { BUILDINGS_TENANT_PAGINATED } from '../../../../shared/utils/urls'
import { usePutRequest } from '../../../../shared/hooks/requests'
import BuildingDetails from '../components/BuildingDetails'

export default function Update({ isOpen, close, item, setData }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateTenant = usePutRequest({ url: `${BUILDINGS_TENANT_PAGINATED}/${item.id}` })

    const update = async (values) => {
        const { success, response, error } = await updateTenant.request({
            data: { ...values },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((val) => (
                    val.id === response.id ? { ...val, ...response } : val
                )),
            }))
            close()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
            anchor="right"
            open={isOpen}
            onClose={close}
        >
            <BuildingDetails
                loading={updateTenant.loading}
                onEdit={update}
                onClose={close}
                item={item}
            />
        </Drawer>
    )
}
