import React from 'react'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import FormTextField from '../../../shared/ui/FormTextField'
import CancelBtn from '../../../shared/ui/CancelBtn'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const FORM_INITIAL_STATE = {
    clientName: '',
    password: '',
    threshold: '',
    spoofingThreshold: '',
}

const VALIDATION_SCHEMA = object().shape({
    clientName: string().when('formType', {
        is: 'details',
        then: string().required('Name is required'),
    }),
    password: string().when('formType', {
        is: 'changePassword',
        then: string().required('Password is required'),
    }),
})

export default function ClientForm({
    onSubmit = () => {},
    onClose = () => {},
    initialValues,
    loading,
    isEdit,
    item,
}) {
    return (
        <Formik enableReinitialize
            initialValues={{
                ...FORM_INITIAL_STATE,
                ...initialValues,
                ...item,
            }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}>
            {({ handleChange }) => (
                <Form className="h-[75%] flex flex-col justify-between overflow-y-auto p-4 gap-10">
                    <div className="flex-col gap-4 overflow-y-auto">

                        <FormTextField
                            required
                            label="Client Name"
                            name="clientName"
                            handleChange={handleChange}
                        />
                        <FormTextField
                            required
                            label="Threshold"
                            name="threshold"
                            handleChange={handleChange}
                        />
                        <FormTextField
                            required
                            label="Spoofing Threshold"
                            name="spoofingThreshold"
                            handleChange={handleChange}
                        />
                        {!isEdit && (
                            <FormTextField
                                required
                                label="Password"
                                name="password"
                                type="password"
                                handleChange={handleChange}
                            />
                        )}
                    </div>

                    <div className="flex items-center justify-end gap-2.5">
                        <CancelBtn
                            onClick={onClose}
                            title="Cancel"
                            type="button" />

                        <PrimaryBtn
                            loading={loading}
                            title={isEdit ? 'Edit' : 'Create'}
                            type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    )
}
