/* eslint-disable */
import React, { useEffect, useState, useMemo } from 'react'
import { Paper, Typography } from '@mui/material'
import { SystemIntegrationFilter } from './SystemIntegrationFilter'
import SearchInput from '../../../shared/ui/SearchInput'
import Table from '../../../shared/ui/Table'
import { useGetRequest } from '../../../shared/hooks/requests'
import { SYSTEM_INTEGRATION } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { SystemIntegrationTableItems } from './SystemIntegrationTableItems'

/**
 * Колонки
 * Обратите внимание, что Difference (7-я колонка) будет обновляться динамически
 */
const baseCols = [
    { id: 1,  title: 'ID',          color: '#1D4ED8' },
    { id: 2,  title: 'MTT_ID',      width: '200',    color: '#23c55d' },
    { id: 3,  title: 'MTT Name',    color: '#23c55d', sx: { width: '200px', maxWidth: '200px' }},
    { id: 4,  title: 'Total',       color: '#1D4ED8' },
    { id: 5,  title: 'Kids',        color: '#1D4ED8' },
    { id: 6,  title: 'Emp',         color: '#1D4ED8' },
    // 7-я колонка (Difference) будет изменяться
    { id: 7,  title: 'Difference',  color: '#B91C1C' },
    { id: 8,  title: 'P_kids',       color: '#23c55d' },
    { id: 9,  title: 'Accepted_kids', color: '#23c55d' },
    { id: 10, title: 'Rejected_kids', color: '#23c55d' },
    { id: 11, title: 'Waiting_kids',  color: '#23c55d' },
    { id: 12, title: 'P_edus',        color: '#23c55d' },
    { id: 13, title: 'Accepted_edus', color: '#23c55d' },
    { id: 14, title: 'Rejected_edus', color: '#23c55d' },
    { id: 15, title: 'Waiting_edus',  color: '#23c55d' },
    { id: 16, title: 'Batafsil' },
]

/**
 * Компонент таблицы
 */
export function SystemIntegrationTable() {
    const { systemFilter, setSystemFilters } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [differenceOrder, setDifferenceOrder] = useState(0)
    // 0: без сортировки, 1: asc, 2: desc

    const loadIntegrations = useGetRequest({
        url: SYSTEM_INTEGRATION,
        params: { district_id: systemFilter.district, visit_date: systemFilter.date }
    })

    // Данные и общее число
    const data = loadIntegrations.response ? loadIntegrations.response.data : []
    const totalItems = loadIntegrations.response ? loadIntegrations.response.totalItems : 0

    const handleSearch = (value) => {
        setSystemFilters({ search: value })
    }

    // Сброс страницы при изменении фильтров
    useEffect(() => {
        setPage(1)
    }, [systemFilter])

    useEffect(() => {
        const params = {
            ...(systemFilter.district && { district_id: systemFilter.district }),
            ...(systemFilter.date && { visit_date: systemFilter.date }),
            ...(systemFilter.mttId && { mtt_id: systemFilter.mttId }),
            page,
            pageSize,
        }

        if (Object.keys(params).length > 2) { // Проверяем, что есть хотя бы один фильтр кроме page и pageSize
            if ((systemFilter.district !== '' && systemFilter.date) || systemFilter.mttId) {
                loadIntegrations.request({params})
            }
        }
    }, [systemFilter.district, systemFilter.date, systemFilter.mttId, page, pageSize])
    /**
     * Обработка клика по колонке Difference
     * переключаем differenceOrder → (0 -> 1 -> 2 -> 0 ...)
     */
    const handleClickDifference = () => {
        setDifferenceOrder((prev) => (prev + 1) % 3)
    }

    /**
     * Колонки с динамической надписью (Difference)
     * Ставим стрелку ↑ / ↓, в зависимости от differenceOrder
     */
    const cols = useMemo(() => {
        // Копируем базовый массив колонок
        const newCols = [...baseCols]
        // Ищем 7-ю колонку (id:7) — или ищем по title: 'Difference'
        const diffIndex = newCols.findIndex((c) => c.id === 7)
        if (diffIndex !== -1) {
            let title = 'Difference'
            if (differenceOrder === 1) title = 'Difference ▲'
            if (differenceOrder === 2) title = 'Difference ▼'

            // Добавим onClick / style
            newCols[diffIndex] = {
                ...newCols[diffIndex],
                title,
                // Дополнительно можно inline style / cursor
                sx: { cursor: 'pointer', color: '#B91C1C', fontWeight: 'bold' },
                // Можно хранить это для Table, если она умеет отслеживать:
                onHeaderClick: handleClickDifference,
            }
        }
        return newCols
    }, [differenceOrder])

    /**
     * Подготавливаем данные: считаем difference
     * и сортируем при необходимости
     */
    const preparedData = useMemo(() => {
        // 1) Считаем difference = totalCount - (totalKids + totalEdus)
        const withDiff = data.map((item) => {
            const totalDifference = item.totalCount - (item.totalKids + item.totalEdus)
            return { ...item, totalDifference }
        })

        // 2) Сортируем, если differenceOrder !== 0
        if (differenceOrder === 1) {
            // Ascending
            return [...withDiff].sort((a, b) => a.totalDifference - b.totalDifference)
        }
        if (differenceOrder === 2) {
            // Descending
            return [...withDiff].sort((a, b) => b.totalDifference - a.totalDifference)
        }
        // 0 — без сортировки
        return withDiff
    }, [data, differenceOrder])

    return (
        <div className="flex flex-col w-[100%] items-center gap-[20px]">
            <div className="w-[100%]">
                <SystemIntegrationFilter />
            </div>

            <Paper square className="flex flex-col w-[100%] gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <div className="flex items-center gap-[15px]">
                        <Typography className="flex items-center gap-1_5 text-m font-600">
                            System Integrations
                        </Typography>
                        <div className="flex items-center gap-[5px]">
                            <span className="text-black font-normal text-m">OneSystem</span>
                            <div className="w-[10px] h-[10px] rounded-[50%] bg-blue-700"></div>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <span className="text-black font-normal text-m">Platon</span>
                            <div className="w-[10px] h-[10px] rounded-[50%] bg-green-500"></div>
                        </div>
                    </div>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={loadIntegrations.loading}
                    totalCount={totalItems}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    /** Важно: передаём уже обновлённые cols, где Difference
                     *  содержит title: 'Difference ▲/▼' и onHeaderClick.
                     */
                    cols={cols}
                    /** Prepared, уже отсортированные данные */
                    rows={preparedData}
                    renderItem={(item) => (
                        <SystemIntegrationTableItems
                            item={item}
                            key={item.id}
                            setData={loadIntegrations.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
