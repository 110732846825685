/* eslint-disable */
import React, { memo, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { AmericaFlag, ButtonIcon, RussianFlag, TopIcon, UzbFlag } from '../../../components/svgs/Svgs'

const langData = [
    { id: 1, lang: 'en', label: 'English', flag: AmericaFlag },
    { id: 2, lang: 'ru', label: 'Русский', flag: RussianFlag },
    { id: 3, lang: 'uz', label: 'O‘zbek', flag: UzbFlag },
]

function LangSelector() {
    const { i18n } = useTranslation()
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem('i18nextLng') || 'en')
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const dropRef = useRef(null)

    const handleLanguageSelect = (language) => {
        setSelectedLang(language.lang)
        localStorage.setItem('i18nextLng', language.lang) // Сохраняем язык
        i18n.changeLanguage(language.lang) // Меняем язык с помощью i18n
        setIsDropdownOpen(false)
    }

    useEffect(() => {
        const storedLang = localStorage.getItem('i18nextLng') || 'en'
        setSelectedLang(storedLang)
        i18n.changeLanguage(storedLang) // Убедиться, что i18n синхронизирован
    }, [i18n])

    useEffect(() => {
        function handleClick(event) {
            if (dropRef.current && !dropRef.current.contains(event.target)) {
                setIsDropdownOpen(false)
            }
        }
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    return (
        <div
            className={cn(css(s.wrapper), 'flex-col items-center justify-center gap-2')}
            ref={dropRef}
        >
            <div
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="h-auto py-[8px] px-[0.6rem] flex items-center justify-center
            gap-[0.6rem] rounded-[10px] bg-[#F7F7F7] dark:bg-[#353535] cursor-pointer"
            >
                {selectedLang === 'ru' && <RussianFlag />}
                {selectedLang === 'uz' && <UzbFlag />}
                {selectedLang === 'en' && <AmericaFlag />}
                <span className="text-[#A5A5A5] dark:text-white">
                    {langData.find((item) => item.lang === selectedLang)?.label}
                </span>
                <span className="text-[#A5A5A5] dark:text-white">
                    {isDropdownOpen ? <TopIcon /> : <ButtonIcon />}
                </span>
            </div>
            {isDropdownOpen && (
                <div
                    className="flex-col items-center translate-y-[70px] transition-all duration-500
                w-[6.75rem] py-[8px] gap-[0.6rem]
                rounded-[10px] bg-[#F7F7F7] dark:bg-[#353535] ease-in-out h-auto absolute z-50 shadow-lg"
                >
                    {langData
                        .filter((item) => item.lang !== selectedLang)
                        .map((item) => (
                            <div
                                onClick={() => handleLanguageSelect(item)}
                                key={item.id}
                                className={cn(
                                    css(s.langBox),
                                    'flex items-center justify-center pointer gap-7 px-2 py-1 cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700'
                                )}
                            >
                                <span className="text-[#A5A5A5] dark:text-white">{item.label}</span>
                                <item.flag />
                            </div>
                        ))}
                </div>
            )}
        </div>
    )
}

export default memo(LangSelector)

const s = StyleSheet.create({
    wrapper: {
        position: 'relative',
        cursor: 'pointer',
    },
    langBox: {
        height: '100%',
        width: '100%',
        ':hover': {
            backgroundColor: 'rgba(118, 118, 128, 0.10)',
        },
    },
})
