import React, { useEffect, useRef, useState } from 'react'
import { Line, Circle, Group, Text, Rect, Image as KonvaImage, Arrow } from 'react-konva'
import { useImage } from 'react-konva-utils'
import { minMax, getMiddlePoint } from '../../../shared/utils/polygonUtils'
import trashIcon from '../../../static/images/trash.png'

/**
 * Polygon - компонент для отображения и управления одним ROI-полигоном.
 *
 * @param {Object[]} [points=[]] - Массив точек полигона, каждая точка: { x: number, y: number }.
 * @param {Object} [colors={}] - Объект с настройками цветов и стилей (lineColor, fillColor, и т.д.).
 * @param {number[]} [flattenedPoints=[]] - Массив координат для Konva.Line (например, [x1, y1, x2, y2, ...]).
 * @param {boolean} [isFinished=false] - Флаг, завершён ли полигон.
 * @param {boolean} [showLabel=false] - Показывать ли метку (label) над полигоном.
 * @param {string} [label='Polygon'] - Текст метки.
 * @param {number} [polygonInd] - Индекс или порядковый номер полигона.
 * @param {boolean} [lineCrossing=false] - Флаг, нужно ли отображать Arrow.
 * @param {Function} [handleDelete=() => {}] - Колбэк удаления ROI.
 * @param {Function} [handlePointDragEnd=() => {}] - Колбэк окончания drag точки.
 * @param {Function} [handleGroupDragEnd=() => {}] - Колбэк окончания drag всей группы.
 * @param {Function} [handleMouseOverStartPoint=() => {}] - Колбэк при наведении на начальную точку полигона.
 * @param {Function} [handleMouseOutStartPoint=() => {}] - Колбэк при уходе с начальной точки полигона.
 * @param {Function} [handlePointDragMove=() => {}] - Колбэк при перемещении точки.
 * @param {Function} [handleGroupDragStart=() => {}] - Колбэк начала drag всей группы.
 * @param {Function} [onClick=() => {}] - Колбэк при клике по группе (полигона).
 */
export default function Polygon({
                                    points = [], // По умолчанию пустой массив
                                    colors = {},
                                    flattenedPoints = [], // По умолчанию пустой массив
                                    isFinished = false,
                                    showLabel = false,
                                    label = 'Polygon',
                                    polygonInd,
                                    lineCrossing = false,
                                    handleDelete = () => {},
                                    handlePointDragEnd = () => {},
                                    handleGroupDragEnd = () => {},
                                    handleMouseOverStartPoint = () => {},
                                    handleMouseOutStartPoint = () => {},
                                    handlePointDragMove = () => {},
                                    handleGroupDragStart = () => {},
                                    onClick = () => {},
                                }) {
    const [stageObject, setStageObject] = useState(null)
    const [minMaxX, setMinMaxX] = useState([0, 0])
    const [minMaxY, setMinMaxY] = useState([0, 0])
    const [textDimensions, setTextDimensions] = useState({ width: 0, height: 0 })

    const textRef = useRef(null)

    // Массив точек точно приводим к массиву, чтобы избежать ошибок
    const safePoints = Array.isArray(points) ? points : []
    const safeFlattenedPoints = Array.isArray(flattenedPoints) ? flattenedPoints : []

    // Цвета по умолчанию, объединяем с переданным colors
    const mergedColors = {
        vertexRadius: 2.5,
        lineColor: '#0B9E34',
        fillColor: 'rgba(11, 158, 52, 0.3)',
        vertexColor: '#FFFFFF',
        vertexStrokeWidth: 1,
        ...colors,
    }

    // =======================
    //   Конфиг drag & mouse
    // =======================
    const handleGroupMouseOver = (e) => {
        const stage = e.target.getStage()
        if (!isFinished || !stage) return
        stage.container().style.cursor = 'pointer'
        setStageObject(stage)
    }

    const handleGroupMouseOut = (e) => {
        const stage = e.target.getStage()
        if (!stage) return
        stage.container().style.cursor = 'default'
    }

    const handleDragInit = () => {
        if (safePoints.length === 0) return
        const arrX = safePoints.map((p) => p.x)
        const arrY = safePoints.map((p) => p.y)
        setMinMaxX(minMax(arrX))
        setMinMaxY(minMax(arrY))
    }

    const groupDragBoundFunc = (pos) => {
        let { x, y } = pos
        if (!stageObject) return { x, y }

        const sw = stageObject.width()
        const sh = stageObject.height()

        // Проверяем границы
        if (minMaxY[0] + y < 0) y = -minMaxY[0]
        if (minMaxX[0] + x < 0) x = -minMaxX[0]
        if (minMaxY[1] + y > sh) y = sh - minMaxY[1]
        if (minMaxX[1] + x > sw) x = sw - minMaxX[1]

        return { x, y }
    }

    const vertexDragBoundFunc = (pos) => {
        let { x, y } = pos
        if (!stageObject) return { x, y }

        const sw = stageObject.width()
        const sh = stageObject.height()

        // Не даём точкам выходить за границы
        if (x > sw) x = sw
        if (x < 0) x = 0
        if (y > sh) y = sh
        if (y < 0) y = 0

        return { x, y }
    }

    // =======================
    //   Trash icon
    // =======================
    const [iconImage] = useImage(trashIcon)

    // =======================
    //   Text label
    // =======================
    useEffect(() => {
        if (textRef.current) {
            const textNode = textRef.current
            setTextDimensions({
                width: textNode.width(),
                height: textNode.height(),
            })
        }
    }, [label, isFinished])

    // Вычисление размеров для контейнера с label и иконкой удаления
    const padding = { top: 10, bottom: 10, left: 12, right: 12 }
    const iconSize = 24
    const gap = 24

    const containerWidth = textDimensions.width + iconSize + padding.left + padding.right + gap
    const containerHeight = Math.max(textDimensions.height, iconSize) + padding.top + padding.bottom

    // =======================
    //   Arrow rotation
    // =======================
    const calculateArrowRotation = () => {
        if (safePoints.length < 2) return 0
        const startX = safePoints[safePoints.length - 2].x
        const startY = safePoints[safePoints.length - 2].y
        const endX = safePoints[safePoints.length - 1].x
        const endY = safePoints[safePoints.length - 1].y
        const angle = Math.atan2(endY - startY, endX - startX) * (180 / Math.PI)
        return angle
    }

    return (
        <Group
            name="polygon"
            draggable={isFinished}
            onDragStart={(e) => {
                handleDragInit()
                handleGroupDragStart(e)
            }}
            onDragEnd={handleGroupDragEnd}
            dragBoundFunc={groupDragBoundFunc}
            onMouseOver={handleGroupMouseOver}
            onMouseOut={handleGroupMouseOut}
            onClick={onClick}
        >
            {/* Линия (многоугольник) */}
            <Line
                key={polygonInd}
                name="line"
                points={safeFlattenedPoints}
                stroke={mergedColors.lineColor}
                strokeWidth={1}
                closed={isFinished}
                fill={mergedColors.fillColor}
            />

            {/* Точки (вершины) */}
            {safePoints.map((point, index) => {
                const { x, y } = point
                const startPointAttr = index === 0
                        ? {
                            onMouseOver: handleMouseOverStartPoint,
                            onMouseOut: handleMouseOutStartPoint,
                        }
                        : null

                return (
                    <Circle
                        name="vertex"
                        key={point.x + point.y}
                        x={x}
                        y={y}
                        radius={mergedColors.vertexRadius}
                        fill={mergedColors.vertexColor}
                        stroke={mergedColors.lineColor}
                        strokeWidth={mergedColors.vertexStrokeWidth}
                        draggable
                        onDragMove={handlePointDragMove}
                        onDragEnd={handlePointDragEnd}
                        dragBoundFunc={vertexDragBoundFunc}
                        {...startPointAttr}
                    />
                )
            })}

            {/* Пример стрелки, если lineCrossing = true и polygonInd === 1 */}
            {lineCrossing && polygonInd === 1 && safePoints.length > 1 && (
                <Arrow
                    fill={mergedColors.lineColor}
                    x={safePoints[safePoints.length - 1].x}
                    y={safePoints[safePoints.length - 1].y}
                    rotation={calculateArrowRotation()}
                    offsetX={-7}
                    offsetY={0}
                />
            )}

            {/* Блок с лейблом и иконкой удаления */}
            {showLabel && safePoints.length > 0 && (
                <Group
                    x={getMiddlePoint(safePoints).x}
                    y={getMiddlePoint(safePoints).y - 40}
                >
                    <Rect
                        width={containerWidth}
                        height={containerHeight}
                        fill={mergedColors.fillColor}
                        stroke={mergedColors.fillColor}
                        strokeWidth={2}
                        cornerRadius={8}
                        offsetX={containerWidth / 2}
                        offsetY={containerHeight / 2}
                    />
                    <Text
                        name={`Text-${label}`}
                        ref={textRef}
                        text={label}
                        fontSize={16}
                        fill="#FFFFFF"
                        x={-containerWidth / 2 + padding.left}
                        y={-textDimensions.height / 2}
                    />
                    <KonvaImage
                        image={iconImage}
                        x={containerWidth / 2 - padding.right - iconSize / 2}
                        y={0}
                        width={iconSize}
                        height={iconSize}
                        offsetX={iconSize / 2}
                        offsetY={iconSize / 2}
                        onClick={handleDelete}
                    />
                </Group>
            )}
        </Group>
    )
}
