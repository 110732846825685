import React from 'react'
import { Drawer } from '@mui/material'
import Details from '../components/Details'
import { TENANT_ENTITIES } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'
import { usePutRequest } from '../../../shared/hooks/requests'

export default function Update({ setData, item, parentOptions, isOpen, close }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const updateEntity = usePutRequest()

    const update = async (values) => {
        if (!values || updateEntity.loading) return

        const { success, response, error } = await updateEntity.request({
            url: `${TENANT_ENTITIES}${item.id}`,
            data: { ...values, parentId: item.hierarchyLevel === 1 ? undefined : values.parentId },
        })

        if (success) {
            setData((prev) => ({
                ...prev,
                items: prev.items.map((v) => (v.id === item.id ? { ...v, ...response } : v)),
            }))
            close()
            showMessage('Successfully updated', 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <Drawer
            sx={{ '& .MuiDrawer-paper': { width: '40%' } }}
            anchor="right"
            open={isOpen}
            onClose={close}
        >
            <Details
                onEdit={update}
                parentOptions={parentOptions}
                onClose={close}
                item={item}
                loading={updateEntity.loading}
            />
        </Drawer>
    )
}
