/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Paper, Typography } from '@mui/material'
import Table from '../../../shared/ui/Table'
import SearchInput from '../../../shared/ui/SearchInput'
import { TenantAdminReportsFilter } from './TenantAdminReportsFilter'
import { useGetRequest } from '../../../shared/hooks/requests'
import { TENANT_REPORTS } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { TenantAdminReportsTableItems } from './TenantAdminReportsTableItems'

const cols = [
    { id: 1, title: 'ID' },
    { id: 2, title: 'Photo' },
    { id: 3, title: 'Name' },
    { id: 4, title: 'Description' },
    { id: 5, title: 'createdAt' },
    { id: 6, title: 'AttendAt' },
    { id: 7, title: 'Status' },
    { id: 8, title: 'Batafsil' },
]

export function TenantAdminReportsTable() {
    const { reportFilter } = useFilter()
    const { dateFilter } = useFilter()
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadReports = useGetRequest({ url: TENANT_REPORTS })
    const data = loadReports.response ? loadReports.response.items : []
    const meta = loadReports.response ? loadReports.response.total : null
    const handleSearch = () => {

    }

    useEffect(() => {
            if(dateFilter.startPeriod && dateFilter.endPeriod) {
                loadReports.request({
                    params: {
                        ...(reportFilter.region !== '' ? { region: reportFilter.region } : {}),
                        ...(reportFilter.district !== '' ? { district: reportFilter.district } : {}),
                        ...(reportFilter.entity_id !== '' && reportFilter.district !== '' ? { tenant_entity_id: reportFilter.entity_id } : {}),
                        start_date: dateFilter.startPeriod,
                        end_date: dateFilter.endPeriod,
                        page,
                        size:pageSize,
                    },
                })
            }
    }, [reportFilter, dateFilter.startPeriod, page, pageSize, dateFilter.endPeriod])
    return (
        <div className="w-full flex flex-col gap-6">
            <div className="w-[100%]">
                <TenantAdminReportsFilter />
            </div>

            <Paper square className="flex flex-col gap-3">
                <div className="flex items-center justify-between px-4 pt-3">
                    <Typography className="flex items-center gap-1_5 text-m font-600">
                        Reports ( 0 )
                    </Typography>

                    <div className="flex-cent gap-4_5">
                        <SearchInput onSearch={handleSearch} />
                    </div>
                </div>
                <Table
                    page={page - 1}
                    totalCount={meta}
                    loading={loadReports.loading}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <TenantAdminReportsTableItems
                            item={item}
                            key={item.id}
                            setData={loadReports.setResponse}
                        />
                    )}
                />
            </Paper>
        </div>
    )
}
