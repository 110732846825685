import React, { useState } from 'react'
import { StyleSheet, css } from 'aphrodite'
import cn from 'classnames'
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player'
import { COLORS } from '../../../shared/utils/colors'
import { WarningIcon, XIconSizeM } from '../../../components/svgs/Svgs'
import LiveActions from './LiveActions'
import { isValidURL } from '../../../shared/utils/string'
import LoadingSpinner from '../../../components/common/LoadingSpinner'
import Button from '../../../components/common/Button'
import { useGetRequest } from '../../../shared/hooks/requests'
import { ENABLE_STREAM } from '../../../shared/utils/urls'
import { useMessage } from '../../../shared/hooks/message'
import useHandleErrors from '../../../shared/hooks/handleErrorMessage'

export default function LiveCamModal({ onClose, item, disableSocket = false }) {
    const showMessage = useMessage()
    const { handleErrorMsg } = useHandleErrors()
    const enableStream = useGetRequest({ url: ENABLE_STREAM.replace('{id}', item.id) })
    const [videoError, setVideoError] = useState('')
    const [loading, setLoading] = useState(true)
    const snapshot = item.snapshots.length ? item.snapshots[item.snapshots.length - 1].snapshotUrl : null

    const handleVideoError = () => {
        setVideoError('Stream cannot be shown due to internet bandwidth restriction')
        setLoading(false)
    }

    const handleVideoReady = () => {
        setLoading(false)
    }

    const handleReconnect = async () => {
        if (enableStream.loading) return
        const { success, response, error } = await enableStream.request({ params: { enable: true } })
        if (success) {
            setVideoError('')
            showMessage(response.message, 'success-msg')
        } else if (error) {
            handleErrorMsg(error)
        }
    }

    return (
        <div>
            <div className={cn(css(s.head), 'flex justify-between')}>
                <h3 className="flex items-center gap-2">
                    Camera
                    <span>&gt;</span>
                    {' '}
                    {item.name}
                </h3>

                <span onClick={onClose}>
                    <XIconSizeM />
                </span>
            </div>

            <div className={cn(css(s.modalBody), 'w-full flex bg-white')}>
                <div
                    className="flex-1 flex-cent justify-center rounded-m overflow-hidden"
                    style={{ position: 'relative' }}
                >
                    {isValidURL(item.streamUrl) && !videoError && (
                        <ReactPlayer
                            url={item.streamUrl}
                            playing
                            onReady={handleVideoReady}
                            onError={handleVideoError}
                            width="100%"
                            height="100%"
                        />
                    )}

                    {loading && (
                        <span
                            className="loader"
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'black',
                            }}
                        >
                            <LoadingSpinner strokeWidth={5} width={48} />
                        </span>
                    )}

                    {videoError && (
                        <div className={cn(css(s.bgContainer), 'flex-cent')}>
                            <div
                                className={css(s.bg)}
                                /* eslint-disable-next-line max-len */
                                style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.40), rgba(0, 0, 0, 0.40)), url(${snapshot})` }}
                            />
                            <div className={cn(css(s.errorMessage), 'flex-col items-center justify-center gap-2_5')}>
                                <div className="flex-col items-center gap-1_5">
                                    <WarningIcon />

                                    <h3 className="text-m font-500">Warning</h3>
                                </div>

                                <p className="text-s font-400">{videoError}</p>

                                <Button className={css(s.reconBtn)} onClick={handleReconnect}>
                                    Reconnect
                                    {' '}
                                    {enableStream.loading
                                        ? <LoadingSpinner width={14} strokeWidth={4} />
                                        : null}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
                {!disableSocket ? <LiveActions id={item.id} /> : null}
            </div>
        </div>
    )
}

const s = StyleSheet.create({
    head: {
        backgroundColor: COLORS.mainBlueBg,
        padding: '13px 16px',
        color: COLORS.white,
        fontSize: 17,
        fontWeight: '500',
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8,
        ':nth-child(1n) > span': {
            cursor: 'pointer',
        },
    },
    modalBody: {
        gap: 43,
        maxHeight: 'calc(100vh - 120px)',
        minHeight: 'calc(100vh - 120px)',
        padding: '20px 22px',
        boxSizing: 'border-box',
    },
    bgContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    bg: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        filter: 'blur(5px)',
        zIndex: 1,
    },
    errorMessage: {
        borderRadius: 10,
        padding: '20px 24px',
        backgroundColor: 'rgba(255, 255, 255, 0.30)',
        backdropFilter: 'blur(10px)',
        border: '1px solid #C3C3C3',
        width: '20rem',
        position: 'relative',
        zIndex: 2,
        color: COLORS.white,
        textAlign: 'center',
        marginTop: '10px',
    },
    reconBtn: {
        padding: '5px 10px',
        borderRadius: 4,
        background: COLORS.white,
        marginTop: 22,
        fontSize: 10,
        fontWeight: '400',
        color: COLORS.black,
    },
})
