import React, { useState } from 'react'
import { Edit } from '@mui/icons-material'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { COLORS } from '../../../shared/utils/colors'
import { XIconSizeM } from '../../../components/svgs/Svgs'
import { useMessage } from '../../../shared/hooks/message'
import { usePostFaceRequest } from '../../../shared/hooks/faceAuthRequest'
import { PHOTO_STATUS } from '../../../shared/utils/urls'

export function Photos({ item, setResponse }) {
    const showMessage = useMessage()
    const postApprove = usePostFaceRequest()
    const [open, setOpen] = useState(false)

    const handleApprove = async () => {
        const response = await postApprove.request({
            url: PHOTO_STATUS,
            data: {
                individual_personal_number: item.individualPersonalNumber,
                status: 'approved',
            },
        })

        if (response.success) {
            showMessage('Successfully Approved', 'success-msg')
            // Assuming that your `response` holds an object with a property `pendingReviews`
            setResponse((prev) => {
                // Ensure prev is defined and an array
                if (Array.isArray(prev.pendingReviews)) {
                    return {
                        ...prev,
                        pendingReviews: prev.pendingReviews
                            .filter((i) => i.individualPersonalNumber !== item.individualPersonalNumber),
                    }
                }
                return prev // Fallback to previous response if structure is not as expected
            })
        }
    }

    const handleReject = async () => {
        const response = await postApprove.request({
            url: PHOTO_STATUS,
            data: {
                individual_personal_number: item.individualPersonalNumber,
                status: 'rejected',
            },
        })

        if (response.success) {
            showMessage('Successfully Rejected', 'success-msg')
            setResponse((prev) => {
                // Ensure prev is defined and an array
                if (Array.isArray(prev.pendingReviews)) {
                    return {
                        ...prev,
                        pendingReviews: prev.pendingReviews
                            .filter((i) => i.individualPersonalNumber !== item.individualPersonalNumber),
                    }
                }
                return prev // Fallback to previous response if structure is not as expected
            })
        }
    }

    return (
        <>
            <div onClick={() => setOpen(true)}>
                <IconButton>
                    <Edit htmlColor={COLORS.disabled} className="cursor-pointer" fontSize="small" />
                </IconButton>
            </div>

            <Dialog
                sx={{
                    '& .MuiDialog-paper': {
                        width: 'auto', // Change width as needed
                        height: 'auto', // Change height as needed
                        maxWidth: 'none',
                        maxHeight: 'none',
                    },
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <div className="flex items-center justify-between p-3 bg-main-100 text-white text-lg font-medium">
                    <Typography>Review Photos</Typography>

                    <XIconSizeM className="cursor-pointer" onClick={() => setOpen(false)} />
                </div>

                <DialogContent style={{ padding: 1, width: '100%' }}>
                    <div className="w-[100%] flex flex-col gap-[10px] bg-white">
                        <div className="w-[100%] h-[50%] flex items-start gap-[5px]">
                            <div className="w-auto h-[50vh]">
                                <img src={item.originalImageUrl} alt="img of mine" className="w-auto h-[100%]" />
                            </div>
                            <div className="w-auto h-[50vh]">
                                <img src={item.imageUrl} alt="img of mine" className="w-auto h-[100%]" />
                            </div>
                        </div>

                        <div className="flex items-center h-auto py-[10px] px-[10px] justify-between">
                            <div className="w-[50%] flex items-center justify-center">
                                <button
                                    onClick={handleApprove}
                                    className="py-[10px] px-[40px] bg-main-100
                                text-white font-normal rounded-[6px]"
                                >
                                    Accept
                                </button>
                            </div>
                            <div className="w-[50%]  flex items-center justify-center">
                                <button
                                    onClick={handleReject}
                                    className="py-[10px] px-[40px]
                                bg-red-500 text-white font-normal rounded-[6px]"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
