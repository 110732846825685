import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { StyleSheet, css } from 'aphrodite'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

function Sidebar() {
    const [activeTab, setActiveTab] = useState(0)
    const attendances = useSelector((state) => state.attendances?.attendances)
    const [displayedData, setDisplayedData] = useState([])
    const prevAttendancesRef = useRef({})

    useEffect(() => {
        let data = []

        if (attendances) {
            if (activeTab === 0) {
                // Объединяем данные из attendances[0] и attendances[1]
                const group0 = attendances[0] || []
                const group1 = attendances[1] || []
                data = [...group0, ...group1]
            } else {
                data = attendances[activeTab] || []
            }
        }

        // Reverse data to show latest at the top
        data = [...data].reverse()
        setDisplayedData(data)

        // Обновляем предыдущие данные для сравнения
        prevAttendancesRef.current = data
    }, [attendances, activeTab])

    const renderContent = () => (
        <div className={css(s.personList)}>
            <TransitionGroup component={null}>
                {displayedData.map((attendance) => (
                    <CSSTransition
                        key={attendance?.id}
                        timeout={500}
                        classNames={{
                            enter: css(s.personEnter),
                            enterActive: css(s.personEnterActive),
                        }}
                    >
                        <div className={css(s.person)}>
                            <img
                                src={attendance?.identity?.photo}
                                className={css(s.personImage)}
                                alt="Person"
                            />
                            <div>
                                <p>
                                    <span className={css(s.personTitle)}>FISH:</span>{' '}
                                    {`${attendance?.identity?.first_name || '-'} ${attendance?.identity?.last_name || '-'}`}
                                </p>
                                <p>
                                    <span className={css(s.personTitle)}>PINFL:</span>{' '}
                                    {attendance?.identity?.pinfl}
                                </p>
                                <p>
                                    <span className={css(s.personTitle)}>Manzil:</span>{' '}
                                    {attendance?.tenant_entity?.name}
                                </p>
                            </div>
                        </div>
                    </CSSTransition>
                ))}
            </TransitionGroup>
        </div>
    )

    return (
        <div className={css(s.sidebar)}>
            <div className={css(s.trapezoid)} />
            <div className={css(s.trapezoidBorder)}>
                <svg
                    width="135"
                    height="8"
                    viewBox="0 0 135 8"
                    fill="#000"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M134.169 0.5L127.139 6.13007H7.81958L0.789062 0.5"
                        stroke="#00FCFC"
                        strokeWidth="0.5"
                    />
                </svg>
            </div>

            <h2 className={css(s.heading)}>KAMERA ORQALI ANIQLANGANLAR</h2>
            <div className={css(s.tabs)}>
                <button
                    className={css(activeTab === 2 ? s.activeTab : s.tab)}
                    onClick={() => setActiveTab(2)}
                >
                    Aniqlangan
                </button>
                <button
                    className={css(activeTab === 3 ? s.activeTab : s.tab)}
                    onClick={() => setActiveTab(3)}
                >
                    Qidiruvda
                </button>
                <button
                    className={css(activeTab === 0 ? s.activeTab : s.tab)}
                    onClick={() => setActiveTab(0)}
                >
                    O'qituvchilar va o'quvchilar
                </button>
            </div>
            {renderContent()}
        </div>
    )
}

const s = StyleSheet.create({
    sidebar: {
        position: 'absolute',
        top: '50%',
        right: '30px',
        height: 'calc(100% - 60px)',
        transform: 'translateY(-50%)',
        width: '500px',
        padding: '25px 12px 12px 12px',
        color: '#00fcfc',
        border: '0.5px solid #00FCFC',
        background: 'linear-gradient(145deg, rgba(0, 255, 255, 0.2) 3%, rgba(0, 0, 0, 0.7) 100%)',
        backdropFilter: 'blur(5px)',
    },
    trapezoidBorder: {
        position: 'absolute',
        width: '135px',
        top: '-1px',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    heading: {
        marginBottom: '15px',
        fontSize: '18px',
        fontWeight: 600,
        color: '#00FCFC',
    },
    tabs: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        backgroundColor: '#003131',
        borderRadius: '4px',
    },
    tab: {
        flex: 1,
        padding: '0px',
        color: '#00FCFC',
        borderRadius: '8px',
        textAlign: 'center',
        cursor: 'pointer',
        height: '26px',
        fontSize: '12px',
        background: 'transparent',
        border: 'none',
    },
    activeTab: {
        flex: 1,
        padding: '0px',
        backgroundColor: '#00FCFC',
        border: '1px solid #00FCFC',
        color: '#1f1f1f',
        borderRadius: '8px',
        textAlign: 'center',
        cursor: 'pointer',
        height: '30px',
        fontSize: '12px',
    },
    personList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflowY: 'auto',
        maxHeight: 'calc(100% - 150px)',
    },
    person: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        borderBottom: '1px solid #00FCFC17',
        paddingBottom: '10px',
        marginBottom: '10px',
    },
    personTitle: {
        color: '#fff',
    },
    personImage: {
        width: '50px',
        height: '60px',
        objectFit: 'cover',
        borderRadius: '4px',
    },
    // Стили для анимации
    personEnter: {
        opacity: 0,
        transform: 'translateY(20px)',
    },
    personEnterActive: {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 0.5s ease, transform 0.5s ease',
    },
})

export default Sidebar
