/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { MenuItem, Paper, Select } from '@mui/material'
import moment from 'moment/moment'
import Skeleton from 'react-loading-skeleton'
import { debounce } from 'lodash'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import { useGetRequest, useLoad } from '../../../shared/hooks/requests'
import { ALLOWED_REGIONS,
    DISTRICTS_LIST,
    TENANT_ENTITY_CUSTOMER_FILTER,
    TENANT_ENTITY_FILTER } from '../../../shared/utils/urls'
import SelectInput from '../../../shared/ui/SelectInput'

export function TenantAdminReportsFilter() {
    const { reportFilter, setReportFilters } = useFilter()
    const { dateFilter, setDateFilter } = useFilter()
    const userType = localStorage.getItem('userType')
    const entityListUrl = userType === 'tenant_admin' ? TENANT_ENTITY_FILTER : TENANT_ENTITY_CUSTOMER_FILTER
    const loadRegions = useLoad({ url: ALLOWED_REGIONS, params: { country_id: '1' } }, [])
    const regions = loadRegions.response ? loadRegions.response : []
    const getDistricts = useGetRequest({ url: DISTRICTS_LIST, params: { regionId: reportFilter.region } })
    const districts = getDistricts.response ? getDistricts.response : []
    const regionOptions = regions.map((region) => ({ value: region.id, label: region.name }))
    const districtOptions = districts.length ? districts.map((district) => ({ value:
        district.id,
    label: district.name })) : []

    const [searchTerm, setSearchTerm] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const getAllEntities = useGetRequest({ url: entityListUrl,
        params: { search: searchTerm,
            regionId: reportFilter.region,
            districtId: reportFilter.district } }, [])
    const data = getAllEntities.response ? getAllEntities.response : []
    const options = data ? data.map((e) => ({ value: e.id,
        label: e.name,
        col1: e.name,
        col2: e.region.name,
        col3: e.district.name })) : []

    console.log(options)

    const handleSearch = useCallback(
        debounce((value) => {
            setSearchTerm(value)
        }, 300),
        [], // only create the debounce function once
    )

    const onSearchChange = (value) => {
        setSearchValue(value)
        handleSearch(value)
    }

    useEffect(() => {
        if (searchTerm !== null || reportFilter.district) {
            getAllEntities.request()
        }
    }, [searchTerm, reportFilter.district])

    useEffect(() => {
        setReportFilters({ district: '' })
        if (reportFilter.region) {
            getDistricts.request()
        }
    }, [reportFilter.region])

    return (
        <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
            <Paper square className="flex flex-col gap-2.5 p-3 overflow-x-auto">
                <h3 className="text-m text-black font-500">Filter</h3>
                <div className="flex items-center gap-2">
                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? regionOptions.find(
                            (v) => v.value === value,
                        )?.label : 'Choose region')}
                        sx={{ height: 37, width: '10rem' }}
                        value={reportFilter.region}
                        onChange={(e) => setReportFilters({ region: e.target.value })}
                    >
                        {!loadRegions.loading && regionOptions.length ? regionOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        )) : loadRegions.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>

                    <Select
                        variant="outlined"
                        displayEmpty
                        renderValue={(value) => (value ? districtOptions.find(
                            (v) => v.value === value,
                        )?.label : 'Choose district')}
                        sx={{ height: 37, width: '10rem' }}
                        value={reportFilter.district}
                        disabled={!districtOptions.length}
                        onChange={(e) => setReportFilters({ district: e.target.value })}
                    >
                        {!getDistricts.loading && districtOptions.length ? districtOptions.map((menuItem) => (
                            <MenuItem key={menuItem.value} value={menuItem.value}>
                                {menuItem?.label}
                            </MenuItem>
                        )) : getDistricts.loading ? (
                            <MenuItem>
                                <Skeleton width="50%" height={30} />
                            </MenuItem>
                        ) : <MenuItem value="">Nothing Found</MenuItem>}
                    </Select>
                    <SelectInput
                        styles={{
                            minWidth: '12rem',
                            '& .MuiInputBase-root': {
                                minHeight: '30px', // Set minimum height for container
                                padding: '4px 8px', // Adjust padding for a compact look
                            },
                            '& .MuiSelect-select': {
                                padding: '4px 8px', // Adjust padding inside the select to reduce height
                                display: 'flex',
                                alignItems: 'center', // Vertically center text
                                minHeight: '20px', // Set the desired minimum height here
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                minHeight: '30px', // Match the outline to the container height
                            },
                            '& .MuiInputLabel-root': {
                                top: '-7px', // Move label slightly upward
                            },
                        }}
                        variant="outlined"
                        name="tenantEntityId"
                        label="Choose entity"
                        selectPlaceholder="Choose entity"
                        onChange={(e) => setReportFilters({ entity_id: e.target.value })}
                        value={reportFilter.entity_id}
                        menuItems={options}
                        loading={getAllEntities.loading}
                        searchable
                        placeholder="Search entities..."
                        onSearch={onSearchChange}
                        searchValue={searchValue}
                        tableMode
                    />
                    <div className="flex gap-2">
                        {/* Start Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="Start Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null}
                                onChange={(e) => {
                                    const formattedStartDate = e ? moment(e)
                                        .format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        startPeriod: formattedStartDate,
                                        endPeriod: prev.endPeriod && moment(formattedStartDate)
                                            .isBefore(moment(prev.endPeriod)) ? prev.endPeriod : formattedStartDate,
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                            />
                        </div>

                        {/* End Date Picker */}
                        <div className="w-[150px]">
                            <DatePicker
                                label="End Date"
                                sx={{
                                    height: 37,
                                    width: '150px',
                                    '& .MuiInputBase-root': { height: 37 },
                                    '& .MuiFormLabel-root': { top: '-8px' },
                                }}
                                inputFormat="DD-MM-YYYY"
                                value={dateFilter.endPeriod ? moment(dateFilter.endPeriod) : null}
                                onChange={(e) => {
                                    const formattedEndDate = e ? moment(e)
                                        .format('YYYY-MM-DD') : null
                                    setDateFilter((prev) => ({
                                        ...prev,
                                        endPeriod: formattedEndDate,
                                    }))
                                }}
                                // renderInput={(params) => <TextField {...params} />}
                                initialFocusedDate={null}
                                minDate={dateFilter.startPeriod ? moment(dateFilter.startPeriod) : null}
                            />
                        </div>
                    </div>
                </div>
            </Paper>
        </LocalizationProvider>
    )
}
