import React, { useState } from 'react'
import DrawerLayout from '../../../../app/layouts/DrawerLayout'
import CameraForm from '../../../camera/components/CameraForm'
import SmartCameraForm from '../../../smartcamera-manage/components/SmartCameraForm'

const navItems = [
    { id: 'detail', name: 'Details' },
]

export default function DeviceDetails({ item, onEdit, onClose, loading, camType }) {
    const [section, setSection] = useState('detail')

    const initialValues = {
        ...item,
        fileName: item.snapshots.length ? item.snapshots[item.snapshots.length - 1].snapshotUrl : '',
        snapshotUrl: item.snapshots.length ? item.snapshots[item.snapshots.length - 1].snapshotUrl : '',
    }

    return (
        <DrawerLayout
            topic="Camera details"
            onClose={onClose}
            name={item.name}
            icons={[]}
        >
            <>
                <div className="border-b border-sepia-200 pb-[1px]">
                    <ul className="w-full flex items-center">
                        {navItems.map((i) => (
                            <li
                                key={i.id}
                                onClick={() => setSection(i.id)}
                                className={
                                    `cursor-pointer text-base font-medium relative
                                    p-2 transition-colors duration-300 
                                    ${section === i.id ? 'text-[#305680] after:w-full' : 'text-[#AFAFAF] after:w-0'}
                                    after:content-[''] after:absolute after:left-0 after:bottom-[-1px] after:border-b-2 
                                    after:border-[#305680] after:transition-width after:duration-300 
                                    hover:text-[#305680] hover:after:w-full`
                                }
                            >
                                {i.name}
                            </li>
                        ))}
                    </ul>
                </div>

                {camType === 'smart' ? (
                    <SmartCameraForm
                        isEdit
                        onSubmit={onEdit}
                        loading={loading}
                        initialValues={item}
                        onClose={onClose}
                    />
                ) : (
                    <CameraForm
                        isEdit
                        onSubmit={onEdit}
                        loading={loading}
                        initialValues={initialValues}
                        onClose={onClose}
                    />
                )}
            </>
        </DrawerLayout>
    )
}
