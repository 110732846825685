/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Modal, Button } from '@mui/material'
import moment from 'moment'
import { useGetRequest, usePostRequest } from '../../../shared/hooks/requests'
import { DAILY_ATTENDANCE, ATTENDANCE_DIFFERENCE } from '../../../shared/utils/urls'
import { useFilter } from '../../../app/contexts/FilterContextProvider'
import LoadingSpinner from '../../../components/common/LoadingSpinner'

const cols = [
    { id: 1, title: 'Attendance ID' },
    { id: 2, title: 'External ID' },
    { id: 3, title: 'Spoofed' },
    { id: 4, title: 'Photo' },
    { id: 5, title: 'Time' },
    { id: 6, title: 'FirstName' },
    { id: 7, title: 'LastName' },
]

const platonCols = [
    { id: 1, title: 'GroupID' },
    { id: 2, title: 'Photo' },
    { id: 3, title: 'KidID' },
    { id: 4, title: 'VisitDate' },
    { id: 5, title: 'Spoofed' },
]

export function DetailModal({ id }) {
    const [difference, setDifference] = useState(true)
    const { systemFilter } = useFilter()
    const [open, setOpen] = useState(false)
    const loadAttendances = useGetRequest({ url: DAILY_ATTENDANCE })
    const sync = usePostRequest({ url: ATTENDANCE_DIFFERENCE })
    const attendances = loadAttendances.response ? loadAttendances.response : {}
    const oneSystem = attendances.oneSystem ? attendances.oneSystem : []
    const platon = attendances.platon ? attendances.platon : []

    const handleOpen = () => {
        setOpen(true)
        makeRequest()
    }

    const makeRequest = () => {
        loadAttendances.request({
            params: {
                tenant_entity_id: id,
                visit_date: systemFilter.date,
                only_difference: difference,
            },
        })
    }

    // Re-request when `difference` changes
    useEffect(() => {
        if (open) {
            makeRequest()
        }
    }, [difference, open])

    const handleClose = () => {
        setOpen(false)
    }

    const handleSync = () => {
        const array = oneSystem.map((item) => item.id)
        sync.request({ data: array })
            .then(() => makeRequest())
    }

    return (
        <>
            <button
                onClick={handleOpen}
                className="px-[15px] py-[10px] bg-main-300 text-white rounded-[10px]"
            >
                Details
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <div
                    className="w-[90%] p-[10px] h-[600px] overflow-auto flex flex-col gap-[20px] rounded-[10px] bg-white"
                >
                    <div className="w-full flex items-center gap-[20px]">
                        <button
                            onClick={() => setDifference(false)}
                            className={`px-[15px] py-[5px] ${
                                !difference ? 'bg-main-300 text-white' : 'bg-gray-100 text-black'
                            }  rounded-[10px]`}
                        >
                            All
                        </button>

                        <button
                            onClick={() => setDifference(true)}
                            className={`px-[15px] py-[5px] ${
                                difference ? 'bg-main-300 text-white' : 'bg-gray-100 text-black'
                            }  rounded-[10px]`}
                        >
                            Difference
                        </button>
                    </div>

                    <div className="w-full h-full flex gap-4">
                        {/* ONE SYSTEM TABLE */}
                        <div className="w-1/2 h-full flex flex-col">
                            <div className="flex items-center justify-between pr-[10px] mb-2">
                                <span className="text-[16px] font-[500] py-[6px] text-black">
                                    OneSystem ({oneSystem.length})
                                </span>
                                {difference && oneSystem.length > 0 ? (
                                    <Button
                                        className="px-[20px] py-[6px] rounded-[10px]"
                                        onClick={handleSync}
                                        disabled={sync.loading}
                                        variant="contained"
                                    >
                                        Sync
                                    </Button>
                                ) : null}
                            </div>

                            {/* Заголовки + горизонтальный скролл */}
                            <div className="overflow-x-auto w-full border rounded-md">
                                <div className="min-w-[800px]">
                                    {/* Заголовок */}
                                    <div className="flex border-b">
                                        {cols.map((col) => (
                                            <div key={col.id} className="p-2 flex-1 min-w-[100px] text-center font-bold">
                                                {col.title}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Тело */}
                                    {loadAttendances.loading ? (
                                        <div className="w-full flex items-center justify-center p-4">
                                            <LoadingSpinner />
                                        </div>
                                    ) : oneSystem.length > 0 ? (
                                        oneSystem.map((item) => (
                                            <div key={item.id} className="flex border-b">
                                                {/* Attendance ID */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.id}
                                                </div>

                                                {/* External ID */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.identity?.externalId}
                                                </div>

                                                {/* Spoofed */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.spoofing ? (item.spoofing.isSpoofed === false ? 'False' : 'True') : 'null'}
                                                </div>

                                                {/* Photo */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    <div className="w-[50px] h-[50px] mx-auto overflow-hidden">
                                                        <img
                                                            src={item.snapshotUrl}
                                                            alt="img"
                                                            className="w-full h-full object-cover"
                                                        />
                                                    </div>
                                                </div>

                                                {/* Time */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {moment(item.attendanceDatetime).format('DD.MM.YYYY HH:mm')}
                                                </div>

                                                {/* FirstName */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.identity?.firstName ?? 'no name'}
                                                </div>

                                                {/* LastName */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.identity?.lastName ?? 'no name'}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="p-4 text-center">Empty</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* PLATON TABLE */}
                        <div className="w-1/2 h-full flex flex-col">
                            <span className="text-[16px] pl-[20px] py-[6px] font-[500] text-black mb-2">
                                Platon ({platon.length})
                            </span>

                            <div className="overflow-x-auto w-full border rounded-md">
                                <div className="min-w-[800px]">
                                    {/* Заголовок */}
                                    <div className="flex border-b">
                                        {platonCols.map((col) => (
                                            <div key={col.id} className="p-2 flex-1 min-w-[100px] text-center font-bold">
                                                {col.title}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Тело */}
                                    {loadAttendances.loading ? (
                                        <div className="w-full flex items-center justify-center p-4">
                                            <LoadingSpinner />
                                        </div>
                                    ) : platon.length > 0 ? (
                                        platon.map((item, idx) => (
                                            <div key={idx} className="flex border-b">
                                                {/* GroupID */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.groupId}
                                                </div>

                                                {/* Photo */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    <div className="w-[50px] h-[50px] mx-auto overflow-hidden">
                                                        <img
                                                            src={`https://s32.realsoft.ai/${item.bucket}/${item.objectName}`}
                                                            alt="img"
                                                            className="w-full h-full object-cover"
                                                        />
                                                    </div>
                                                </div>

                                                {/* KidID */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.kidId}
                                                </div>

                                                {/* VisitDate */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {item.visitDate}
                                                </div>

                                                {/* Spoofed */}
                                                <div className="p-2 flex-1 min-w-[100px] text-center">
                                                    {String(item.isSpoofed)}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="p-4 text-center">Empty</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
