import React, { useState } from 'react'
import { IconButton, Paper, Typography } from '@mui/material'
import { Search } from '@mui/icons-material'
import Table from '../../../shared/ui/Table'
import Create from '../features/Create'
import { useFaceLoad } from '../../../shared/hooks/faceAuthRequest'
import { CLIENTSPAGINATION } from '../../../shared/utils/urls'
import ClientTableItem from './ClientTableItem'

export function ClientTable() {
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const loadClients = useFaceLoad({ url: CLIENTSPAGINATION, params: { page, size: pageSize } }, [page, pageSize])
    const data = loadClients.response ? loadClients.response.items : []
    const meta = loadClients.response ? loadClients.response : {}

    const cols = [
        {
            id: 1,
            title: 'Created time',
            align: 'left',
            width: '30%',
        },
        {
            id: 2,
            title: 'Name',
            align: 'left',
            width: '25%',
        },
        {
            id: 3,
            title: 'Threshold',
            align: 'left',
        },
        {
            id: 4,
            title: 'Spoofing threshold',
            align: 'left',
        },
        {
            id: 5,
            title: 'Master users',
            align: 'left',
            width: '15%',
        },
        { id: 6,
            title: 'Action',
            width: '25%',
            align: 'right' },
    ]

    return (
        <Paper square>
            <div className="bg-white w-[100%]">
                <div className="flex items-center justify-between h-[60px] px-4">
                    <Typography className="text-[20px] font-normal text-black">
                        Clients List ({meta.total ? meta.total : 0})
                    </Typography>

                    <div className="flex-cent gap-2">
                        <Create setData={loadClients.setResponse} />

                        <IconButton>
                            <Search fontSize="small" />
                        </IconButton>
                    </div>
                </div>

                <Table
                    page={page - 1}
                    loading={loadClients.loading}
                    totalCount={meta.total}
                    rowsPerPage={pageSize}
                    onPageChange={(e, newPage) => setPage(newPage + 1)}
                    onRowsPerPageChange={(e) => {
                        setPageSize(parseInt(e.target.value, 10))
                        setPage(1)
                    }}
                    cols={cols}
                    rows={data}
                    renderItem={(item) => (
                        <ClientTableItem
                            key={item.id}
                            item={item}
                            setData={loadClients.setResponse} />
                    )}
                />
            </div>
        </Paper>
    )
}
