import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { useDispatch, useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'
import moment from 'moment'
import * as Yup from 'yup'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import MultiSelect from '../../../shared/ui/MultiSelect'
import { COLORS } from '../../../shared/utils/colors'
import { useLoad } from '../../../shared/hooks/requests'
import { IDENTITY_LIST } from '../../../shared/utils/urls'
import { redo, undo, updatePolygonColors } from '../../../shared/store/polygonSlice'
import { RedoIcon, UndoIcon } from '../../../components/svgs/Svgs'
import { ColorPaletteIcon } from '../../../components/svgs/SvgPictures'
import SelectInput from '../../../shared/ui/SelectInput'
import FormTextField from '../../../shared/ui/FormTextField'
import PrimaryBtn from '../../../shared/ui/PrimaryBtn'

const formInitialValues = {
    label: 'Polygon 1',
    labels: [],
    description: '',
    type: '',
    userId: null,
    peopleCountThreshold: null,
    safeZoneStartTime: null,
    safeZoneEndTime: null,
}

const VALIDATION_SCHEMA = Yup.object().shape({
    label: Yup.string().required('Roi should be named'),
    labels: Yup.array().required('Type should be defined'),
    description: Yup.string().optional(),
    // type: Yup.string().when('labels', {
    //     is: (labels) => labels && labels.length && labels.includes('work-analytics'),
    //     then: () => Yup.string().required('Type should be defined'),
    //     otherwise: () => Yup.string().optional(),
    // }),
    // userId: Yup.number().when('labels', {
    //     is: (labels) => labels && labels.length && labels.includes('work-analytics'),
    //     then: () => Yup.number().required('User should be assigned'),
    //     otherwise: () => Yup.number().optional(),
    // }),
    // people_count_threshold: Yup.number().when('labels', {
    //     is: (labels) => labels && labels.length && labels.includes('overcrowd-detection'),
    //     then: () => Yup.number().required('Threshold should be defined'),
    //     otherwise: () => Yup.number().optional(),
    // }),
    // safe_zone_start_time: Yup.date().when('labels', {
    //     is: (labels) => labels && labels.length && labels.includes('safe-zone'),
    //     then: () => Yup.date().required('Start time should be defined'),
    //     otherwise: () => Yup.date().optional(),
    // }),
    // safe_zone_end_time: Yup.date().when('labels', {
    //     is: (labels) => labels && labels.length && labels.includes('safe-zone'),
    //     then: () => Yup.date().required('End time should be defined'),
    //     otherwise: () => Yup.date().optional(),
    // }),
})

const typeOptions = [
    { value: 'worker', label: 'Main workspace' },
    { value: 'client', label: 'Service workspace' },
]

const detectionTypeOptions = [
    { value: 'work-analytics', label: 'Work analytics' },
    { value: 'safe-zone', label: 'Safe zone' },
    { value: 'overcrowd-detection', label: 'Overcrowd detection' },
]

const detectionObjectTypeOptions = [
    { value: 'person', label: 'Person' },
    { value: 'car', label: 'Car' },
]

export default function RoiForm({ onSubmit, loading }) {
    const dispatch = useDispatch()
    const polygons = useSelector((state) => state.polygon.polygons)
    const activePolygonIndex = useSelector((state) => state.polygon.activePolygonIndex)
    const shouldCloseForm = useSelector((state) => state.polygon.shouldCloseForm)
    const [color, setColor] = useState('#0B9E34')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [isFormVisible, setIsFormVisible] = useState(true)

    const loadIdentityUsers = useLoad({ url: IDENTITY_LIST, params: { page: 1, size: 100 } }, [])
    const userOptions = loadIdentityUsers.response && loadIdentityUsers.response.items
        ? loadIdentityUsers.response.items
            .map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })) : []
    // const userOptions = loadCameraUsers.response && loadCameraUsers.response.items
    //     ? loadCameraUsers.response.items
    //         .map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })) : []

    // const loadIdentityUsers = useLoad({

    const handleColorChange = (c) => {
        setColor(c.hex)
        dispatch(updatePolygonColors({ id: polygons[activePolygonIndex].id, color: c.hex }))
    }

    useEffect(() => {
        if (shouldCloseForm) {
            setIsFormVisible(false)
        }
    }, [shouldCloseForm])

    useEffect(() => {
        if (polygons.length > 0) {
            transformArray(polygons[0].flattenedPoints)
        }
    }, [polygons])

    useEffect(() => {
        console.log(polygons)
    }, [])

    const transformArray = (array) => {
        const resultArray = []
        for (let i = array.length - 2; i >= 0; i -= 2) {
            resultArray.push(array[i], array[i + 1])
        }
        return resultArray
    }

    return isFormVisible ? (
        <Formik
            enableReinitialize
            initialValues={{ ...formInitialValues, ...polygons[0] }}
            validationSchema={VALIDATION_SCHEMA}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, handleChange }) => (
                <LocalizationProvider dateAdapter={AdapterMoment} locale="ru">
                    <div className="flex-col gap-2_5">
                        <Form className={cn(css(s.formBody), 'flex-col items-end gap-7_5')}>
                            <div className="w-full flex-col gap-2_5">
                                <FormTextField
                                    required
                                    label="Name"
                                    name="label"
                                    handleChange={handleChange}
                                />

                                <FormTextField
                                    required
                                    label="Description"
                                    name="description"
                                    handleChange={handleChange}
                                />
                                <MultiSelect
                                    name="labels"
                                    onChange={({ selected }) => setFieldValue('labels', selected)}
                                    label="Types"
                                    // loading={getCameras.loading}
                                    selectedValues={values.labels}
                                    options={detectionTypeOptions}
                                />
                                <SelectInput
                                    name="detectionObjectType"
                                    label="Type of Object"
                                    menuItems={detectionObjectTypeOptions}
                                    value={values.detectionObjectType}
                                    onChange={(e) => setFieldValue('detectionObjectType', e.target.value)}
                                />
                                {
                                    values.labels && values.labels.length > 0
                                    && values.labels.includes('work-analytics') && (
                                        <>
                                            <SelectInput
                                                name="identityId"
                                                label="Assigned identity"
                                                menuItems={userOptions}
                                                value={values.identityId}
                                                onChange={(e) => setFieldValue('identityId', e.target.value)}
                                            />
                                            <SelectInput
                                                name="workspaceType"
                                                label="Type of Service"
                                                menuItems={typeOptions}
                                                value={values.workspaceType}
                                                onChange={(e) => setFieldValue('workspaceType', e.target.value)}
                                            />
                                        </>
                                    )
                                }
                                {
                                    values.labels && values.labels.length > 0
                                    && values.labels.includes('overcrowd-detection') && (
                                        <FormTextField
                                            label="Threshold"
                                            name="peopleCountThreshold"
                                            handleChange={handleChange}
                                        />
                                    )
                                }
                                {/* {
                                    values.labels && values.labels.length > 0
                                    && values.labels.includes('safe-zone') && (
                                        <>
                                            <TimePicker
                                                label="Start time"
                                                value={moment(values.safeZoneStartTime, 'HH:mm')}
                                                onChange={(date) => {
                                                    console.log(date)
                                                    setFieldValue('safeZoneStartTime', date)
                                                }}
                                                ampm={false}
                                                minutesStep={1} />
                                            <TimePicker
                                                label="End time"
                                                value={moment(values.safeZoneEndTime, 'HH:mm')}
                                                onChange={(date) => setFieldValue('safeZoneEndTime', date)}
                                                ampm={false}
                                                sx={{
                                                }}
                                                minutesStep={1} />
                                        </>
                                    )
                                } */}

                                {
                                    values.labels && values.labels.length > 0
                                    && values.labels.includes('safe-zone') && (
                                        <>
                                            <TimePicker
                                                label="Start time"
                                                value={
                                                    values.safeZoneStartTime
                                                        ? moment(values.safeZoneStartTime, 'HH:mm:ss')
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    setFieldValue('safeZoneStartTime', date
                                                        ? date.format('HH:mm:ss') : '')
                                                }}
                                                ampm={false}
                                                minutesStep={1}
                                            />
                                            <TimePicker
                                                label="End time"
                                                value={
                                                    values.safeZoneEndTime
                                                        ? moment(values.safeZoneEndTime, 'HH:mm:ss')
                                                        : null
                                                }
                                                onChange={(date) => {
                                                    setFieldValue('safeZoneEndTime', date
                                                        ? date.format('HH:mm:ss') : '')
                                                }}
                                                ampm={false}
                                                minutesStep={1}
                                            />
                                        </>
                                    )
                                }

                            </div>

                            <PrimaryBtn
                                loading={loading}
                                title="Save"
                                type="submit"
                            />
                        </Form>

                        <ul className={cn(css(s.toolsCont), 'flex items-center relative')}>
                            <li className="flex-col items-center gap-2" onClick={() => dispatch(undo())}>
                                <UndoIcon />
                                Undo
                            </li>

                            <li className="flex-col items-center gap-2" onClick={() => dispatch(redo())}>
                                <RedoIcon />
                                Redo
                            </li>

                            <li
                                className="flex-col items-center gap-2 relative"
                                onClick={() => setShowColorPicker(!showColorPicker)}
                            >
                                <ColorPaletteIcon />

                                <h3>Color palette</h3>

                                {showColorPicker && (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: -300,
                                            left: 150,
                                            zIndex: 1000,
                                        }}
                                    >
                                        <SketchPicker
                                            color={color}
                                            onChange={handleColorChange}
                                            onClose={() => setShowColorPicker(false)}
                                        />
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </LocalizationProvider>
            )}
        </Formik>
    ) : null
}

const s = StyleSheet.create({
    btn: {
        height: 38,
        backgroundColor: COLORS.disabled,
        padding: '0 25px',
        color: COLORS.white,
    },
    submitBtn: {
        backgroundColor: COLORS.mainBlueBg,
        ':hover': {
            backgroundColor: COLORS.mainBlue,
        },
    },
    formBody: {
        position: 'relative',
        padding: '22px 16px',
        borderRadius: 10,
        background: COLORS.white,
        ':after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: '-15px',
            transform: 'translateY(-50%)',
            borderWidth: '20px 20px 20px 0',
            borderStyle: 'solid',
            borderColor: `transparent ${COLORS.white} transparent transparent`,
        },
    },
    toolsCont: {
        padding: '10px 0',
        borderRadius: 10,
        border: '1px solid #F8F8F8',
        background: COLORS.white,
        ':nth-child(1n) > *': {
            borderRight: '1px solid #F8F8F8',
            listStyleType: 'none',
            padding: '0 20px',
            height: 'inherit',
            cursor: 'pointer',
            userSelect: 'none',
        },
        ':nth-child(1n) > :last-child': {
            borderRight: 'none',
        },
        position: 'relative',
    },
})
