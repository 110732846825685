/* eslint-disable */
import React, { useState } from 'react';
import { Modal } from '@mui/material';

export function DetailsModal({ data }) {
    const [open, setOpen] = useState(false);
    const [expandedKeys, setExpandedKeys] = useState({}); // Tracks expanded state for each key
    const [copySuccess, setCopySuccess] = useState(false); // Tracks the visibility of the "Copied!" message

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    // Copies the value to the clipboard
    const copyToClipboard = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Hide "Copied!" after 2 seconds
        } catch (err) { /* empty */ }
    };

    // Toggles expansion for a specific key
    const toggleExpand = (key) => {
        setExpandedKeys((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    // Truncates long text and adds "..." with an option to expand
    const renderValue = (key, value) => {
        const limit = 70; // Character limit for truncation
        if (typeof value === 'string' && value.length > limit) {
            return (
                <>
                    {expandedKeys[key] ? value : `${value.substring(0, limit)}...`}
                    <button
                        onClick={() => toggleExpand(key)}
                        className="ml-2 text-blue-500"
                    >
                        {expandedKeys[key] ? 'Show Less' : 'Show More'}
                    </button>
                </>
            );
        }
        return (
            <span
                onClick={() => copyToClipboard(value)}
                className="cursor-pointer"
                title="Click to copy"
            >
                {value}
            </span>
        );
    };

    // Recursively renders JSON with indicators for objects
    const renderJson = (obj, parentKey = '') =>
        Object.entries(obj).map(([key, value]) => {
            const fullKey = parentKey ? `${parentKey}.${key}` : key;

            return (
                <div key={fullKey} className="mb-2">
                    <div className="flex items-center gap-2">
                        {typeof value === 'object' && value !== null ? (
                            <span role="img" aria-label="folder" className="text-yellow-500">
                                📁 {/* Folder icon */}
                            </span>
                        ) : (
                            <span role="img" aria-label="file" className="text-green-500">
                                📄 {/* File icon */}
                            </span>
                        )}
                        <strong>{key}:</strong>
                    </div>
                    {typeof value === 'object' && value !== null ? (
                        <div className="ml-6">
                            {expandedKeys[fullKey] ? (
                                <>
                                    {renderJson(value, fullKey)}
                                    <button
                                        onClick={() => toggleExpand(fullKey)}
                                        className="text-blue-500 text-sm"
                                    >
                                        Collapse
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={() => toggleExpand(fullKey)}
                                    className="text-blue-500 text-sm"
                                >
                                    Expand
                                </button>
                            )}
                        </div>
                    ) : (
                        renderValue(fullKey, value)
                    )}
                </div>
            );
        });

    return (
        <>
            <div className="w-[100%] flex items-center justify-center">
                <button
                    onClick={handleOpen}
                    className="px-[15px] rounded-[15px] py-[10px] bg-main-300 text-white"
                >
                    Details
                </button>
            </div>

            {/* Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="flex items-center justify-center"
            >
                <div
                    className="relative w-[50%] max-h-[80%]
                overflow-auto bg-gray-900 text-green-400 p-[20px] rounded-lg"
                >
                    <h2 className="text-lg font-bold mb-[10px]">JSON Details</h2>
                    <div className="text-sm whitespace-pre-wrap">
                        {renderJson(data)}
                    </div>

                    {/* Centered Copy Success Message */}
                    {copySuccess && (
                        <div
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2
                            bg-gray-800 text-white px-4 py-2 rounded-md shadow-md"
                        >
                            Copied!
                        </div>
                    )}
                </div>
            </Modal>
        </>
    );
}
