import React, { useState } from 'react'
import cn from 'classnames'
import { StyleSheet, css } from 'aphrodite'
import { Grid, Card as MuiCard, CardContent, DialogContent, Dialog, IconButton } from '@mui/material'
import { Fullscreen } from '@mui/icons-material'
import inactiveImg from '../../../../static/images/inactive-camera.png'
import CameraControls from './CameraControls'
import Update from '../features/device-features/Update'
import { COLORS } from '../../../../shared/utils/colors'
import Delete from '../features/device-features/Delete'

export default function LiveStreamCard({ item, camType, setData }) {
    const [openControls, setOpenControls] = useState(false)

    return (
        <Grid item xs={12} sm={6} md={6} lg={6}>
            <MuiCard>
                <CardContent sx={{
                    display: 'flex',
                    padding: 0,
                    paddingBottom: 0,
                    flexDirection: 'column',
                    gap: '20px',
                    height: '200px',
                    cursor: 'pointer',
                    borderRadius: '15px',
                    ':last-child': { paddingBottom: 0 },
                }}
                >
                    <div className={cn(css(s.cont), 'relative w-full')}>
                        {item.lastSnapshotUrl ? (
                            <div className={bgStyle(item.lastSnapshotUrl)} />
                        ) : (
                            <div className={cn(css(s.inactive), 'flex-cent')}>
                                <img src={inactiveImg} alt="#INACTIVE" />
                            </div>
                        )}

                        <div className={cn(
                            css(s.hoverTools),
                            'flex items-center justify-center gap-4 absolute',
                        )}
                        >
                            <Update item={item} camType={camType} setData={setData} />

                            <Delete id={item.id} setData={setData} camType={camType} />

                            {camType === 'smart' && (
                                <IconButton onClick={() => setOpenControls(true)}>
                                    <Fullscreen htmlColor={COLORS.white} />
                                </IconButton>
                            )}

                            <Dialog
                                fullWidth
                                open={openControls}
                                onClose={() => setOpenControls(false)}
                                sx={{ '& .MuiPaper-root': { backgroundColor: 'transparent', maxWidth: '100%' } }}
                            >
                                <DialogContent sx={{ padding: 0, borderRadius: '8px', backgroundColor: 'transparent' }}>
                                    <CameraControls
                                        item={item}
                                        onClose={() => setOpenControls(false)}
                                    />
                                </DialogContent>
                            </Dialog>
                        </div>
                    </div>
                </CardContent>
            </MuiCard>
        </Grid>
    )
}

const s = StyleSheet.create({
    hoverTools: {
        top: '100%',
        width: 'inherit',
        height: 'inherit',
        background: 'rgba(48, 86, 128, 0.80)',
        transition: 'all ease .1s',
    },
    cont: {
        overflow: 'hidden',
        height: '100%',
        ':hover': {
            ':nth-child(1n) > :last-child': {
                top: 2,
            },
        },
    },
    inactive: {
        height: 'inherit',
        backgroundColor: '#888',
    },
})

const bgStyle = (url) => css(StyleSheet.create({
    bg: {
        backgroundImage: `url(${url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%',
    },
}).bg)
