/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import { Modal } from '@mui/material'
import moment from 'moment/moment'

const cols = [
    {
        id: 1,
        title: 'ID',
    },
    {
        id: 2,
        title: 'Photo',
    },
    {
        id: 3,
        title: 'version',
    },
    {
        id: 4,
        title: 'CreatedAt',
    },
]

export function PhotosModal({ data }) {
    const [option, setOption] = useState('Photos') // Default option is "Photos"
    const [open, setOpen] = useState(false)

    // Refs to track button elements
    const buttonRefs = {
        Photos: useRef(null),
        Nimadir: useRef(null),
        Nimadir2: useRef(null),
    }

    // State for underline style
    const [underlineStyle, setUnderlineStyle] = useState({ width: 0, left: 0 })

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    // Function to update the underline position and width
    const updateUnderline = () => {
        const activeButton = buttonRefs[option]?.current
        if (activeButton) {
            setUnderlineStyle({
                width: activeButton.offsetWidth,
                left: activeButton.offsetLeft,
            })
        }
    }

    // Update underline when the modal opens or the option changes
    useEffect(() => {
        if (open) {
            // Ensure underline is calculated after the modal has rendered
            setTimeout(() => {
                updateUnderline()
            }, 0)
        }
    }, [open, option])

    console.log(data)

    return (
        <>
            <button
                onClick={handleOpen}
                className="px-[15px] py-[10px] bg-main-300 text-white text-center rounded-[10px]"
            >
                Details
            </button>
            <Modal open={open} onClose={handleClose} className="flex items-center justify-center">
                <div className="w-[60%] flex flex-col items-center gap-[20px] bg-white h-[500px] overflow-hidden">
                    <div className="w-[100%] px-[15px] py-[15px] flex items-center
                     relative"
                    >
                        {/* Underline */}
                        <div
                            className="absolute bottom-0 h-[2px] bg-main-dark-300 transition-all"
                            style={{
                                width: `${underlineStyle.width}px`,
                                left: `${underlineStyle.left}px`,
                            }}
                        />
                        {/* Buttons */}
                        <button
                            ref={buttonRefs.Photos}
                            onClick={() => setOption('Photos')}
                            className={`${
                                option === 'Photos' ? 'text-main-dark-300' : 'text-main-100'
                            } text-[16px] font-[500] relative z-10`}
                        >
                            O`zgartirilgan rasmlar
                        </button>
                    </div>
                    {option === 'Photos' ? (
                        <div className="w-[100%] flex flex-col items-center gap-[10px]">
                            <div className="flex items-center justify-between w-[100%] py-[6px]
                             border-b pb-[6px]"
                            >
                                {cols?.map((item) => (
                                    <div className="w-[20%] flex items-center justify-center">
                                        <span
                                            key={item.id}
                                            className="text-[14px] font-[400] text-black"
                                        >
                                            {item.title}
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <div className="w-[100%] max-h-[450px] overflow-auto">
                                {data?.map((item) => (
                                    <div
                                        className="w-[100%] flex items-center py-[6px] pr-[4px]
                                         border-b justify-between"
                                    >
                                        <div className="w-[20%] flex items-center justify-center">
                                            <span className="text-[16px] font-[400] text-black">{item.id}</span>
                                        </div>
                                        <div className="w-[20%] flex items-center justify-center">
                                            <div className="w-[50px] h-[50px] overflow-hidden">
                                                <img
                                                    src={item.url}
                                                    alt="img"
                                                    className="w-[100%] h-[100%] object-cover"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-[20%] flex items-center justify-center">
                                            <span className="text-[16px] font-[400] text-black">
                                                {item.version}
                                            </span>
                                        </div>
                                        <div className="w-[20%] flex items-center justify-center">
                                            <span className="text-[16px] font-[400] text-black">
                                                {moment(item.createdAt).format('DD.MM.YYYY')}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span>Empty</span>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}
